const TOKEN_KEY = "loginToken";
const REMEMBER_ME = "rememberMe";
const EMAIL_CONFIRMED = "isEmailConfirmed";

export const login = (token, rememberMe) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(REMEMBER_ME, rememberMe);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REMEMBER_ME);
  localStorage.removeItem(EMAIL_CONFIRMED);
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const EmailConfirmation = (isConfirm, token) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(EMAIL_CONFIRMED, isConfirm);
};

export const isLogin = () => {
  if (localStorage.getItem(REMEMBER_ME) === "true" ||
    document.referrer.includes("login") ||
    (document.referrer.includes("freelancer") && localStorage.getItem('loginToken')) ||
    (document.referrer.includes("email-confirmation") && localStorage.getItem(EMAIL_CONFIRMED) === "true")) {
    return true;
  }
  return false;
};

export const setSlug = (slug) => {
  if (localStorage.getItem('loginToken') == null) {
    sessionStorage.setItem('slug', slug)
  }
}