import * as React from "react";
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  MonthAgenda,
  TimelineViews,
  ResourcesDirective,
  ResourceDirective,
  Inject,
  TimelineMonth,
  HeaderRowsDirective,
  HeaderRowDirective,
} from "@syncfusion/ej2-react-schedule";
import axios from "axios";
import memoizeOne from "memoize-one";
import debounce from "lodash/debounce";
import { getToken, logout } from "../utils";
import { L10n } from "@syncfusion/ej2-base";
import { Week, WorkWeek, Month, Agenda } from "@syncfusion/ej2-react-schedule";
import "react-whatsapp-widget/dist/index.css";
import moment from "moment";
import * as Sentry from "@sentry/react";
import mintLogo from "../../assets/MiNT_LOGO1.svg";
import EditMenuDialog from "./EditMenuDialog";
import PwaButton from "../Pwa/index";
import "react-notifications/lib/notifications.css";
//Pusher for notifications
import Pusher from "pusher-js";
import "./index.css";
import "./clientPwa.scss";
import BookingPopUpModal from "../BookingPopUpModal";

L10n.load({
  "en-US": {
    schedule: {
      newEvent: "Schedule Appointment",
    },
  },
});

var stars = {};
var initialIndex = null;
var changeRowFlag = false;
var yearData = null;
var yearFlag = false; // eslint-disable-line
var isBusy = false;  // eslint-disable-line

export default class ClienPwa extends React.PureComponent {
  scheduleObj = {};
  mulCellDrag = false;
  currentCellIndex = -1;
  has_busy_or_booked = 0;
  uTurnDateCell = -1;
  isStartBusy = false;
  directionChanged = false;
  cycleCompleted = false;
  startIndex = null;
  direction = null;
  lastDirection = null;
  from = null;
  to = null;
  fromDate = null;
  toDate = null;
  busyFoundAt = null;
  alreadyChanged = true;
  ownerData1 = [{ OwnerText: "Nancy", id: 1 }];
  constructor() {
    super(...arguments);
    this.state = {
      bookingStartDate: "",
      bookingEndDate: "",
      data: [],
      all_data: [],
      alldata: [],
      all_bookings: [],
      disableDays: [],
      isModalVisible: false,
      ownerData: [{ OwnerText: "Nancy", id: 1 }],
      originalOwnerData: [],
      phone: process.env.REACT_APP_FLXIBLE_SUPPORT,
      starsonSearch: true,
      showNotificationMenu: false,
      showMenuDialog: false,
      newNotificationArrived: false,
      newNotificationsCount: 0,
      isRequiredAllChecked: false,
      isLocationChecked: false,
      freelancerId: null,
      countDays: null,
      isBookingPopUpOpen: false,
      userInfo: {},
      boxes: [],
      deleteBookingPayload: {},
      isAvailableCell: true,
      showEditMenuDialog: false,
      topPos: null,
      userId: null,
      displayName: "",
      profession: "",
      portfolio_link: "",
      email: "",
      mounted: false,
      isSearching: false,
    }
    var that = this;

    var pusher = new Pusher(process.env.REACT_APP_PUSHER_TOKEN, {
      cluster: process.env.REACT_APP_CLUSTER,
    });
    var channel = pusher.subscribe("booking-channel");
    this.enableScrollingOnLeftColumn =
      this.enableScrollingOnLeftColumn.bind(this);
    this.BookingPopulation = this.BookingPopulation.bind(this);
    this.debouncedOnChange = debounce(this.onChange.bind(this), 500);
    this.bindPusherEvents(that, channel);
    sessionStorage.removeItem("slug");
  }

  setSliderPostion = () => {
    setTimeout(() => {
      window.scrollTo({
        top: parseInt(sessionStorage.getItem("client_Y_Pos")),
        left: sessionStorage.getItem("client_X_Pos"),
        behavior: "smooth",
      });
    }, 1700);
  };

  componentDidMount = () => {
    document.title = "Flxibl | " + localStorage.getItem("agency");
    if (window.location.origin.includes("https://www.flxibl.io")) {
      window.location.replace(
        "https://www.flxibl.com" + window.location.pathname
      );
    }
    this.enableScrollingOnLeftColumn(5);
    setTimeout(() => {
      document
        .querySelector(".e-content-wrap")
        .addEventListener("scroll", (event) => {
          sessionStorage.client_X_Pos =
            document.querySelector(".e-content-wrap").scrollLeft;
          sessionStorage.client_Y_Pos =
            document.querySelector(".e-content-wrap").scrollTop;
        });
    }, 1700);
    this.fetchData("");
  };

  enableScrollingOnLeftColumn(sec) {
    setTimeout(() => {
      var scrollableDiv = document.querySelector(".e-resource-column-wrap");
      var targetDiv = document.querySelector(".e-content-wrap");
      scrollableDiv.addEventListener("scroll", () => {
        var pos = scrollableDiv.scrollTop;
        targetDiv.scrollTop = pos;
      });
    }, sec * 1000);
  }

  bindPusherEvents = (that, channel) => {
    var currenUser = localStorage.getItem("resource_id");
    channel.bind(`create-event/${currenUser}`, function (data) {
      that.setState({ newBook: true });
    });
    channel.bind(`busy-event/${currenUser}`, function (data) {
      let date = data.message.split("/");
      if (date && date.length > 2) {
        let datesToDisable = JSON.parse(date[0]);
        for (let i = 0; i < datesToDisable.length; i++) {
          let element = document.getElementsByClassName(
            `${datesToDisable[i]}-${date[1]}`
          );
          if (element && element.length !== 0 && date[2] === "true") {
            if (element.length === 2) {
              element[1].classList.remove(
                "e-disableCell",
                "backendClass",
                "e-busy-weekend"
              );
            } else {
              element[0].classList.remove(
                "e-disableCell",
                "backendClass",
                "e-busy-weekend"
              );
            }
          } else if (element && element.length !== 0 && date[2] === "false") {
            if (!element[0].classList.contains("accepted")) {
              if (element.length === 2) {
                element[1].classList.add("e-disableCell");
                element[1].classList.add("backendClass");
                element[1].classList.add("e-busy-weekend");
              } else {
                element[0].classList.add("e-disableCell");
                element[0].classList.add("backendClass");
                element[0].classList.add("e-busy-weekend");
              }
            }
          }
        }
        that.setState({ newBook: true });
      } else if (date && date.length === 2) {
        let element = document.getElementsByClassName(`${date[0]}`);
        if (element && element.length !== 0 && date[1] === "true") {
          if (element.length === 2) {
            element[1].classList.remove(
              "e-disableCell",
              "backendClass",
              "e-busy-weekend"
            );
          } else {
            element[0].classList.remove(
              "e-disableCell",
              "backendClass",
              "e-busy-weekend"
            );
          }
        } else if (element && element.length !== 0 && date[1] === "false") {
          if (element.length === 2) {
            element[1].classList.add("e-disableCell");
            element[1].classList.add("backendClass");
            element[1].classList.add("e-busy-weekend");
          } else {
            element[0].classList.add("e-disableCell");
            element[0].classList.add("backendClass");
            element[0].classList.add("e-busy-weekend");
          }
        }
        that.setState({ newBook: true });
      }
      // NotificationManager.success(data.message, data.title, 5000);
      if (data.message.length !== 0) {
        that.fetchData(document.querySelector(".searchInput").value);
      }
    });

    channel.bind(`delete-event/${currenUser}`, function (data) {
      // NotificationManager.success(data.message, data.title, 5000);
      that.setState({ newBook: true });
      that.fetchData(document.querySelector(".searchInput").value);
    });

    channel.bind(`client-respond-event/${currenUser}`, function (data) {
      // NotificationManager.success(data.message, data.title, 5000);
      that.setState({ newBook: true });
      that.fetchData(document.querySelector(".searchInput").value);
    });

    channel.bind(`invite_accepted/${currenUser}`, function (data) {
      that.setState({ newBook: true });
      that.fetchData(document.querySelector(".searchInput").value);
    });
  };
  unbindPusherEvents = (channel) => {
    var currenUser = localStorage.getItem("resource_id");
    channel.unbind(`create-event/${currenUser}`);
    channel.unbind(`accepted-event/${currenUser}`);
    channel.unbind(`busy-event/${currenUser}`);
    channel.unbind(`rejected-event/${currenUser}`);
    channel.unbind(`delete-event/${currenUser}`);
    channel.unbind(`client-respond-event/${currenUser}`);
  };

  fetchData = async (value) => {
    var that = this;
    let url = `${process.env.REACT_APP_ROUTE}/api/v1/networks`;

    if (value?.length > 0)
      url = `${process.env.REACT_APP_ROUTE}/api/v1/networks?q=` + value
    axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + getToken()
      },
      mode: 'cors',
      credentials: 'include'
    })
      .then(function (response) {
        response?.data && (response?.data?.sort((a, b) => {
          a = a?.profession || '';
          b = b?.profession || '';
          return a?.localeCompare(b)
        }));
        var data = []
        var all_book = []
        response.data?.map((a, index) => {  // eslint-disable-line
          if (a.bookings)
            a.bookings?.map(booking => {  // eslint-disable-line
              data.push({
                Id: booking.id, Subject: booking.title,
                status: booking.status,
                StartTime: new Date(booking.startDate),
                EndTime: new Date(booking.endDate),
                colorField: booking.color_code,
                is_manager: booking.is_manager,
                IsAllDay: true,
                OwnerId: booking.profile_id,
                i: index,
                projectName: booking.project_name,
                description: booking.description,
                location: booking.location,
                rate: booking.rate,
                totalRate: booking.total_rate,
                start_date: booking.startDate,
                end_date: booking.endDate,
                startTime: booking.booking_start_time,
                endTime: booking.booking_end_time,
                currency: booking.currency,
                duration: booking.duration,
                is_associated_with_mint: a.is_associated_with_mint,
                displayName: a.displayName,
                is_location_added: booking.is_location_added,
                is_required_all: booking.is_required_all,
                is_optional: booking.is_optional,
              })
            })
          if (a.all_bookings)
            a.all_bookings?.map(booking => {  // eslint-disable-line
              all_book.push({
                Id: booking.id, Subject: booking.title,
                status: booking.status,
                StartTime: new Date(booking.startDate),
                EndTime: new Date(booking.endDate),
                colorField: booking.color_code,
                is_manager: booking.is_manager,
                IsAllDay: true,
                OwnerId: booking.profile_id,
                i: index,
                projectName: booking.project_name,
                description: booking.description,
                location: booking.location,
                rate: booking.rate,
                totalRate: booking.total_rate,
                start_date: booking.startDate,
                end_date: booking.endDate,
                startTime: booking?.booking_start_time,
                endTime: booking.booking_end_time,
                currency: booking.currency,
                duration: booking.duration,
                is_associated_with_mint: a.is_associated_with_mint,
                displayName: a.displayName,
                is_location_added: booking.is_location_added,
                is_required_all: booking.is_required_all,
                is_optional: booking.is_optional,
              })
            })
          if (a.rating) {
            stars["rating_" + a.id] = a.rating
          }
        })
        var filteredDataBooking = data?.filter(a => a.status !== "canceled")
        let value = document.querySelector('.searchInput').value;
        if (!that.state.mounted && !that.state.isSearching) {
          that.setState({ ownerData: response.data, data: filteredDataBooking, mounted: true, originalOwnerData: response.data });
        }
        else if (that.state.isSearching && value.length === 0) {
          that.setState({ ownerData: response.data, data: filteredDataBooking, isSearching: false, originalOwnerData: response.data });
        }

        if (
          sessionStorage.getItem("freelancerDeleted") === "true" ||
          sessionStorage.getItem("invitionSent") === "true"
        ) {

          that.setState({
            ownerData: response.data,
            originalOwnerData: response.data,
          });
          that.ownerData1 = response.data;
          sessionStorage.setItem("freelancerDeleted", null);
          sessionStorage.setItem("invitionSent", null);
        }
        if (response.data.length === that.ownerData1.length && !that.state.isSearching) {
          const isNotMatching = response.data.some(obj1 => {
            return that.ownerData1.some(obj2 => {
              return obj1.profession !== obj2.profession || obj1.portfolio_link !== obj2.portfolio_link;
            });
          });
          if (isNotMatching) {
            that.setState({ ownerData: response.data, originalOwnerData: response.data })
            that.ownerData1 = response.data;
          }
        }
        if (that.state.newBook) {
          let events;
          let filteredOwnerEvents;
          let deletedObjects;
          let missingObjects;
          let removePending;
          if (document.querySelector(".searchInput").value.length !== 0) {
            events = that.scheduleObj.getEvents();
            filteredOwnerEvents = events.filter(obj1 => response?.data?.some(obj2 => obj1.OwnerId === obj2.id))
            deletedObjects = filteredOwnerEvents.filter(obj1 => !data.some(obj2 => obj1.Id === obj2.Id))
            missingObjects = data.filter(obj1 => !filteredOwnerEvents.some(obj2 => obj1.Id === obj2.Id && obj1.status === obj2.status));
            removePending = data.filter(obj1 => filteredOwnerEvents.some(obj2 => obj1.Id === obj2.Id && obj1.status !== obj2.status));
          }
          else {
            events = that.scheduleObj.getEvents();
            deletedObjects = events.filter(obj1 => !data.some(obj2 => obj1.Id === obj2.Id))
            missingObjects = data.filter(obj1 => !events.some(obj2 => obj1.Id === obj2.Id && obj1.status === obj2.status));
            removePending = data.filter(obj1 => events.some(obj2 => obj1.Id === obj2.Id && obj1.status !== obj2.status));
          }
          if (response.data.length > that.ownerData1.length) {
            that.setState({ ownerData: response.data, originalOwnerData: response.data })
            that.ownerData1 = response.data;
          }
          if (response.data.length === that.ownerData1.length) {
            let changed = false;
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].profession !== that.ownerData1[i].profession || response.data[i].profession !== that.ownerData1[i].profession) {
                changed = true;
                break;
              }
            }
            if (changed) {
              that.setState({ ownerData: response.data, originalOwnerData: response.data })
            }
            that.ownerData1 = response.data;
          }
          if (removePending && removePending.length !== 0 &&
            sessionStorage.getItem("inApp") === "false") {
            that.scheduleObj.deleteEvent(removePending[0]?.Id);
            let element = document.getElementsByClassName(`${moment(removePending[0].StartTime).format('YYYY-MM-DD')}-${removePending[0]?.OwnerId}`)
            if (element !== undefined && element.length !== 0) {
              element[0].classList.remove("pending", "accepted", "optional", "e-disableCell", "backendClass", "e-busy-weekend");
              element[0].classList.add("accepted");
            }
          }
          else if (deletedObjects && deletedObjects.length !== 0) {
            for (var arr = [], dt = new Date(deletedObjects[0].StartTime); dt <= new Date(deletedObjects[0].EndTime); dt.setDate(dt.getDate() + 1)) {
              const indexToRemove = that.ownerData1.filter((data) => data.Id === deletedObjects[0].Id)[0]?.freelancer_total_busy_days?.indexOf(moment(dt).format('YYYY-MM-DD'));
              let element = document.getElementsByClassName(`${moment(dt).format('YYYY-MM-DD')}-${deletedObjects[0]?.OwnerId}`)
              if (element !== undefined && element.length !== 0) {
                element[0].classList.remove("pending", "accepted", "optional", "e-disableCell", "backendClass", "e-busy-weekend");
              }
              if (indexToRemove !== -1) {
                that.ownerData1.filter((data) => data.Id === deletedObjects[0].Id)[0]?.freelancer_total_busy_days?.splice(indexToRemove, 1);
              }
            }
            that.scheduleObj.deleteEvent(deletedObjects[0]?.Id);
          }
          if (missingObjects && missingObjects.length !== 0 && that.state.mounted && sessionStorage.getItem("inApp") === "false") {
            for (var arr = [], dt = new Date(missingObjects[0].StartTime); dt <= new Date(missingObjects[0].EndTime); dt.setDate(dt.getDate() + 1)) {  // eslint-disable-line
              const indexToRemove = that.ownerData1.filter((data) => data.Id === missingObjects[0].Id)[0]?.freelancer_total_busy_days?.indexOf(moment(dt).format('YYYY-MM-DD'));
              let element = document.getElementsByClassName(`${moment(dt).format('YYYY-MM-DD')}-${missingObjects[0]?.OwnerId}`)
              if (element !== undefined && element.length !== 0) {
                element[0].classList.remove("pending", "accepted", "optional", "e-disableCell", "backendClass", "e-busy-weekend");
                element[0].classList.add(`${missingObjects[0].status}`)
              }
              if (indexToRemove !== -1) {
                that.ownerData1.filter((data) => data.Id === missingObjects[0].Id)[0]?.freelancer_total_busy_days?.splice(indexToRemove, 1);
              }
            }
            that.scheduleObj.addEvent(missingObjects);
          }
          that.setState({ newBook: false });
          sessionStorage.setItem("inApp", "false")
        }
        that.ownerData1 = response.data;
        setTimeout(() => {
          that.updateStars();
        }, 1000)
        that.setSliderPostion();
      })
      .catch(function (error) {
        Sentry.captureException(error);

      });
  }

  getClickableLink = (link) => {
    return link.startsWith("http://") || link.startsWith("https://")
      ? link
      : `http://${link}`;
  };

  getMonthHeaderText(props) {
    document
      .querySelectorAll(".e-resource-cells.e-child-node")
      .forEach((element) => {
        element.classList.add("client-pwa-resource");
      });
    var date = props.date;
    var today = new Date();
    var currentDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];
    today = new Date(today.getTime() - today.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];
    var color = today === currentDate ? "#4888FF" : "";

    return (
      <div className="topday">
        {props.date.toLocaleString("en-us", { weekday: "short" }).substr(0, 2)}
        <div
          className="topdayContent"
          style={{
            background: color,
            color: today === currentDate ? "white" : "#3F434A",
          }}
        >
          <p>
            {parseInt(
              props.date.toLocaleString("en-us", { day: "2-digit" }),
              10
            )}
          </p>
        </div>
      </div>
    );
  }
  resourceHeaderTemplate(props) {
    var date_elm = document.getElementsByClassName("e-tbar-btn-text");
    var todayDate = date_elm[0].innerText.split(" ");
    if (moment(new Date()).format("YYYY") === todayDate[0]) {
      yearData = todayDate[0];
    } else if (moment(new Date()).format("YYYY") === todayDate[1]) {
      yearData = todayDate[1];
    } else if (moment(new Date()).format("YYYY") === todayDate[3]) {
      yearData = todayDate[3];
    }
    date_elm[0].innerText = "";
    date_elm[0].innerHTML = `<span class='header_year'>${yearData}</span>`;
    return (
      <div
        className="template-wrap"
        id={props.resourceData.id}
        displayName={props?.resourceData?.displayName}
        email={props?.resourceData?.email}
        portfolio_link={props?.resourceData?.portfolio_link}
        profession={props?.resourceData?.profession}
        phone={props?.resourceData?.phone}
        onContextMenu={this.handleOpenEditMenuDialog}
      >
        <div
          className="resource-details"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <div className="resource-name" style={{ marginTop: 9 }}>
            <div className="topHederResource">
              {props?.resourceData?.portfolio_link ? (
                <a
                  style={{ textDecoration: "none" }}
                  href={this.getClickableLink(
                    props.resourceData.portfolio_link
                  )}
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="search-name freelacner-name">
                    {props?.resourceData?.displayName !== undefined
                      ? props?.resourceData?.displayName?.length > 13
                        ? props?.resourceData?.displayName.substr(0, 13) + "..."
                        : props?.resourceData?.displayName
                      : ""}
                  </div>
                </a>
              ) : (
                <span>
                  <div className="search-name freelacner-name">
                    {props?.resourceData?.displayName !== undefined
                      ? props?.resourceData?.displayName?.length > 13
                        ? props?.resourceData?.displayName.substr(0, 13) + "..."
                        : props?.resourceData?.displayName
                      : ""}
                  </div>
                </span>
              )}
              <div className="ratings" id={"rating_" + props.resourceData.id}>
                <div
                  onMouseLeave={() =>
                    this.removeStarColor(`rating_${props.resourceData.id}`)
                  }
                >
                  <span
                    onMouseEnter={() =>
                      this.setStarColor(`rating_${props.resourceData.id}-${1}`)
                    }
                    onClick={() =>
                      this.setStar(`rating_${props.resourceData.id}-${1}`)
                    }
                    className={`rating_${props.resourceData.id}`}
                  >
                    {this.state.starsonSearch ? (
                      <i
                        className="fa fa-star-o"
                        style={{
                          color: "rgb(203, 211, 227)",
                          fontSize: "12px",
                          marginLeft: "0px",
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                  <span
                    onMouseEnter={() =>
                      this.setStarColor(`rating_${props.resourceData.id}-${2}`)
                    }
                    onClick={() =>
                      this.setStar(`rating_${props.resourceData.id}-${2}`)
                    }
                    className={`rating_${props.resourceData.id}`}
                  >
                    {this.state.starsonSearch ? (
                      <i
                        className="fa fa-star-o"
                        style={{
                          color: "rgb(203, 211, 227)",
                          fontSize: "12px",
                          marginLeft: "0px",
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                  <span
                    onMouseEnter={() =>
                      this.setStarColor(`rating_${props.resourceData.id}-${3}`)
                    }
                    onClick={() =>
                      this.setStar(`rating_${props.resourceData.id}-${3}`)
                    }
                    className={`rating_${props.resourceData.id}`}
                  >
                    {this.state.starsonSearch ? (
                      <i
                        className="fa fa-star-o"
                        style={{
                          color: "rgb(203, 211, 227)",
                          fontSize: "12px",
                          marginLeft: "0px",
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
              <div className="client_profession">
                {props.resourceData?.profession
                  ? this.titlelizeString(
                    props.resourceData?.profession?.split("_").join(" ")
                  )
                  : ""}
                {props?.resourceData?.is_associated_with_mint && (
                  <img className="mintLogo1" src={mintLogo} alt="mintLogo1" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  setStar(name) {
    var str = name.split("-");
    var active_index = str[1];
    var classname = str[0];
    stars[classname] = active_index;
    var freelancer_id = classname.replace("rating_", "");
    var resource_type = localStorage.getItem("user_type");
    var resource_id = parseInt(localStorage.getItem("resource_id"));
    var data = {
      resource_type: resource_type,
      resource_id: resource_id,
      newRating: active_index,
    };
    var url = `${process.env.REACT_APP_ROUTE}/freelancers/${freelancer_id}/ratings`;
    axios.post(url, data).then((response) => { });
    this.enableScrollingOnLeftColumn(1);
  }

  updateStars(sec) {
    setTimeout(() => {
      Object.keys(stars).forEach(function (key) {
        var all_stars = document.getElementsByClassName(key);
        var value = stars[key];
        Array.from(all_stars).map((star, index) => {  // eslint-disable-line
          if (index + 1 <= value && star.children.length > 0) {
            star.children[0].style.color = "rgb(254, 181, 11)";
            star.children[0].classList.value = "fa fa-star";
          } else if (star.children.length > 0) {
            star.children[0].style.color = "rgb(203, 211, 227)";
          }
        });
      });
    }, sec * 1000);
  }

  setStarColor(name) {
    var str = name.split("-");
    var active_index = str[1];
    var classname = str[0];
    var all_stars = document.getElementsByClassName(classname);
    Array.from(all_stars).map((star, index) => {  // eslint-disable-line
      if (index + 1 <= active_index && star.children.length > 0) {
        star.children[0].style.color = "rgb(254, 181, 11)";
        star.children[0].classList.value = "fa fa-star";
      } else if (star.children.length > 0) {
        star.children[0].style.color = "rgb(203, 211, 227)";
        star.children[0].classList.value = "fa fa-star-o";
      }
    });
  }
  removeStarColor(name) {
    var str = name.split("-");
    var classname = str[0];
    var all_stars = document.getElementsByClassName(classname);
    Array.from(all_stars).map((star, index) => {  // eslint-disable-line
      if (
        index + 1 <= stars[star.classList.value] &&
        star.children.length > 0
      ) {
        star.children[0].style.color = "rgb(254, 181, 11)";
        star.children[0].classList.value = "fa fa-star";
      } else if (star.children.length > 0) {
        star.children[0].style.color = "rgb(203, 211, 227)";
        star.children[0].classList.value = "fa fa-star-o";
      }
    });
  }
  onActionBegin(args) {
    if (args.requestType === "toolbarItemRendering") {
      // This block is execute before toolbarItem render
    }
    if (args.requestType === "dateNavigate" && args.event === undefined) {
      this.enableScrollingOnLeftColumn(1);
      document.querySelector(".searchInput").value = "";
      // this.fetchData(document.querySelector(".searchInput").value);
      yearFlag = false;
      let date_elm = document.getElementsByClassName("e-tbar-btn-text");
      let elem = document.getElementsByClassName("e-day e-title")[0];
      if (date_elm !== undefined && elem !== undefined) {
        date_elm[0].innerHTML = `<span class='header_year'>${elem.innerHTML}</span>`;
        yearData = elem.innerHTML;
      }
      // This block is executed before previous and next navigation
    }
    if (args.requestType === "viewNavigate") {
      // This block is execute before view navigation
    }
    if (args.requestType === "eventCreate") {
    }
    if (args.requestType === "eventChange") {
      // This block is execute before an appointment change
      if (args.data.StartTime < new Date(new Date().setHours(0, 0, 0, 0))) {
        args.cancel = true;
      }
    }
    if (args.requestType === "eventRemove") {
    }
  }
  titlelizeString(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  handleOk = () => {
    this.setState({ isModalVisible: false });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  updateBoxBackgroundColor = () => {
    Array.from(this.state.boxes).map((box) =>
      box.classList.remove("e-selected-cell")
    );
  };

  async onPopupOpen(args) {
    this.isStartBusy = false;
    this.cycleCompleted = false;
    this.currentCellIndex = -1;
    this.direction = null;
    this.lastDirection = null;
    this.directionChanged = false;
    this.startIndex = null;
    var sd = null;
    var ed = null;
    var rowIndex = null

    const tableElement = document.getElementsByClassName(
      "e-schedule-table e-content-table e-auto-height"
    )[0].tBodies[0];
    var countDays = 0;
    var arr = [].slice.call(document.getElementsByClassName("e-selected-cell"));
    let busyDays;
    busyDays =
      this.state.ownerData[
        args.data.i !== undefined ? args.data.i : args.data.groupIndex
      ]?.freelancer_total_busy_days;

    this.updateStars(0);

    this.setState({ boxes: arr });

    if (args.type === "QuickInfo" || this.scheduleObj.isAdaptive) {
      args.cancel = true;
    }

    if (this.from < this.to) {
      countDays = this.to - this.from + 1;
      const fromDateString = this.fromDate.substring(0, 10);
      const toDateString = this.toDate.substring(0, 10);
      const myDiv = document.getElementsByClassName(`${moment(fromDateString).format('YYYY-MM-DD')}-${args.data.OwnerId}`);
      rowIndex = myDiv[0]?.getAttribute("data-group-index");
      sd = moment(fromDateString).format("YYYY/MM/DD");
      ed = moment(toDateString).format("YYYY/MM/DD");
    } else if (this.from > this.to) {
      countDays = this.from - this.to + 1;
      const fromDateString = this.fromDate.substring(0, 10);
      const toDateString = this.toDate.substring(0, 10);
      const myDiv = document.getElementsByClassName(`${moment(toDateString).format('YYYY-MM-DD')}-${args.data.OwnerId}`);
      rowIndex = myDiv[0]?.getAttribute("data-group-index");
      sd = moment(toDateString).format("YYYY/MM/DD");
      ed = moment(fromDateString).format("YYYY/MM/DD");
    } else if (this.from !== undefined) {
      countDays = 1;
      const fromDateString = this.fromDate.substring(0, 10);
      const myDiv = document.getElementsByClassName(`${moment(fromDateString).format('YYYY-MM-DD')}-${args.data.OwnerId}`);
      rowIndex = myDiv[0]?.getAttribute("data-group-index");
      sd = moment(fromDateString).format("YYYY/MM/DD");
    }
    busyDays = this.ownerData1[rowIndex]?.freelancer_total_busy_days;
    if (
      (args.data.status === "pending" ||
        args.data.status === "accepted" ||
        args.data.status === "optional") &&
      args.data.duration > 1
    ) {
      const myDiv = document.getElementsByClassName(
        `${args.data.start_date}-${args.data.OwnerId}`
      );
      const dataGroupIndex = myDiv[0].getAttribute("data-group-index");
      rowIndex = dataGroupIndex;
      busyDays = this.ownerData1[dataGroupIndex]?.freelancer_total_busy_days;
      var daylist = this.getDaysArray(
        new Date(`${args.data.start_date}`),
        new Date(`${args.data.end_date}`)
      );

      busyDays = busyDays?.filter((day) => {
        if (daylist.includes(day)) {
          return false;
        }
        return true;
      });
    } else if (
      (args.data.status === "pending" ||
        args.data.status === "accepted" ||
        args.data.status === "optional") &&
      args.data.duration === 1
    ) {
      const myDiv = document.getElementsByClassName(
        `${args.data.start_date}-${args.data.OwnerId}`
      );
      const dataGroupIndex = myDiv[0].getAttribute("data-group-index");
      rowIndex = dataGroupIndex;
      busyDays = this.ownerData1[dataGroupIndex]?.freelancer_total_busy_days;
      busyDays = busyDays?.filter((day) => day !== args.data.start_date);
    }

    const user_info = {
      ...args.data,
      ...this.state.ownerData.filter((item) => item.id === args.data?.OwnerId)[0],
      id: args.data.OwnerId,
      sd: sd ? moment(new Date(sd)) : moment(args.data.start_date),
      ed: ed ? moment(new Date(ed)) : moment(args.data.end_date),
      countDays: countDays ? countDays : args.data.duration,
      is_required_all: args.data.is_required_all,
      currency: this.ownerData1.filter((item) => item.id === args.data?.OwnerId)[0].company_currency,
      busyDays: busyDays,
      last_confirmed_booking: this.ownerData1.filter((item) => item.id === args.data?.OwnerId)[0].last_confirmed_booking,
      i: parseInt(rowIndex),
      location: args.data.location
    };

    if (args.data.is_manager) {
      args.element.classList.add("e-disableCell");
    }

    if (args.type === "QuickInfo" || this.scheduleObj.isAdaptive) {
      if (
        args.target.className.includes("e-disableCell") &&
        !this.mulCellDrag
      ) {
        return "";
      }
      if (args.data.Id) {
        var usertype = localStorage.getItem("user_type");
        if (usertype === "manager" || args.data.status) {
          args.cancel = true;
          this.setState({
            isBookingPopUpOpen: true,
            userInfo: user_info,
            deleteBookingPayload: { args: args, tableElement: tableElement },
            isAvailableCell: args.data.status === "optional" ? true : false,
          });
        }
      } else if (countDays >= 1 && changeRowFlag === false) {
        this.setState({ isBookingPopUpOpen: true, userInfo: user_info });
      }
      initialIndex = null;
      changeRowFlag = false;
      this.from = null;
      this.to = null;
      this.busyFoundAt = null;
      this.toDate = null;
      this.fromDate = null;
    } else if (args.type === "Editor") {
      args.cancel = true;
    }
    this.setSliderPostion();
  }


  getDaysArray = (start, end) => {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(moment(dt).format("YYYY-MM-DD"));
    }
    return arr;
  };

  onPopupClose = () => {
    this.setState({ isLocationChecked: false });
    this.isStartBusy = false;
    this.cycleCompleted = false;
    this.currentCellIndex = -1;
    this.direction = null;
    this.lastDirection = null;
    this.directionChanged = false;
    this.startIndex = null;
  };

  onRenderCell(args) {
    var date_elm = document.getElementsByClassName("e-tbar-btn-text");
    var todayDate = date_elm[0].innerText.split(" ");
    var currentDate = new Date();
    if (moment(currentDate).format("YYYY") === todayDate[0]) {
      yearData = todayDate[0];
    } else if (moment(currentDate).format("YYYY") === todayDate[1]) {
      yearData = todayDate[1];
    } else if (moment(currentDate).format("YYYY") === todayDate[3]) {
      yearData = todayDate[3];
    }
    date_elm[0].innerText = "";
    date_elm[0].innerHTML = `<span class='header_year'>${yearData}</span>`;
    var index = args.groupIndex;
    if (!index) index = 0;
    if (
      args.date &&
      this.state.ownerData[index] &&
      this.state.ownerData[index].busy_days &&
      this.state.ownerData[index].busy_days.length > 0
    ) {
      if (
        args.date &&
        this.state.ownerData[index] &&
        this.state.ownerData[index].busy_days
      ) {
        var dateString = moment(args.date).format("YYYY-MM-DD");
        if (this.state.ownerData[index].busy_days.includes(dateString)) {
          if (
            !Array.from(args.element.classList).includes("pending") &&
            !Array.from(args.element.classList).includes("accepted")
          ) {
            args?.date &&
              args.element.classList.add(
                `${dateString}-${this.state.ownerData[index]?.id}`
              );
            args.element.classList.add("e-disableCell");
            args.element.classList.add("backendClass");
          }
          if (
            !Array.from(args.element.classList).includes("e-work-days") &&
            !Array.from(args.element.classList).includes("e-header-cells")
          ) {
            args?.date &&
              args.element.classList.add(
                `${dateString}-${this.state.ownerData[index]?.id}`
              );
            args.element.classList.add("e-busy-weekend");
            isBusy = true;
          }
        }
      }
    }
    if (
      args.date &&
      this.state.ownerData[index] &&
      Array.from(args.element.classList).includes(
        "e-work-cells",
        "e-child-node"
      )
    ) {
      args?.date &&
        args.element.classList.add(
          `${moment(args.date).format("YYYY-MM-DD")}-${this.state.ownerData[index]?.id
          }`
        );
      args.element.classList.add("client_pwa_view");
    }
  }
  oneventRendered(args) {
    if (args.data.is_manager) {
      args.element.classList.add("e-disableCell");
      args.element.firstChild.style.background = args.data.colorField;
    } else {
      if (args.data.status === "accepted") {
        args.element.style.backgroundColor = "yellow";
        args.element.style.color = "black";
      }
    }
  }

  onChange(arg) {
    let query = arg?.target?.value?.toLowerCase();
    this.filterData(query);
    setTimeout(() => {
      var date_elm = document.getElementsByClassName("e-tbar-btn-text");
      if (date_elm[0])
        date_elm[0].innerHTML = `<span class='header_year'>${yearData}</span>`;
    }, 1000);
    window.dispatchEvent(new Event("resize"));
  }

  setOriginalData() {
    this.setState({ ownerData: this.state.originalOwnerData });
  }

  filterData(query) {
    // let currentFreelancers = [];
    let freelancerData = [...document.getElementsByClassName("search-name")];
    let freelancerRowEelement =
      document.getElementsByClassName(" e-content-table")[0];
    let eventTable = document.getElementsByClassName("e-event-table")[0];
    let clientProfession = document.getElementsByClassName("client_profession");
    freelancerData.map((freelancer, index) => {  // eslint-disable-line
      freelancer.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.style.display =
        "";
      freelancerRowEelement.children[1].children[index].style.display = "";
      eventTable.children[index].style.display = "";
    });
    if (query !== "") {
      freelancerData.map((freelancer, index) => {  // eslint-disable-line
        if (
          !freelancer.textContent.toLowerCase().includes(query) &&
          !clientProfession[index].textContent
            .toLowerCase()
            .replace(/[^a-zA-Z ]/g, " ")
            .includes(query)
        ) {
          freelancer.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.style.display =
            "none";
          freelancerRowEelement.children[1].children[index].style.display =
            "none";
          eventTable.children[index].style.display = "none";
        }
      });
    }
    window.dispatchEvent(new Event("resize"));
  }

  BookingPopulation(status, isRequiredAll, bookingDuration, OwnerId, dateString, isOptional, colorField, projectName, startTime, endTime, duration, pName) {
    if (status === 'accepted' && isRequiredAll) {
      return (<div style={{ height: "66px", width: `${40.5 * duration + 3.5 * duration - 3.5 * 2}px`, borderColor: colorField, borderRadius: "4px" }}>
        <div style={{ backgroundColor: colorField, height: "66px", minWidth: 'fit-content', borderRadius: "4px" }} className="template-wrap" >
          {projectName ?
            <div className="textWrap displayNameOnAcceptedBooking">{projectName}</div>
            : ""}
          <div className="textWrap timeTxt">{moment.utc(startTime).format('HH:mm')}-{moment.utc(endTime).format('HH:mm')}</div>
        </div>

      </div>);
    }

    else if (status === 'accepted' && !isRequiredAll) {
      return (<div style={{ height: "66px", width: `37px`, borderColor: colorField, borderRadius: "4px" }}>
        <div style={{ backgroundColor: colorField, height: "66px", width: `37px`, borderRadius: "4px" }} className="template-wrap" >
          {projectName ?
            <div className="textWrap displayNameOnAcceptedBooking" >
              {pName.length > 2
                ? pName[0]
                : projectName.length <= 5
                  ? projectName
                  : projectName.substring(0, 4)}
              <br />
              {pName.length > 2
                ? pName[1]?.substring(0, 4) + ".."
                : projectName.length <= 5
                  ? ""
                  : projectName.substring(4, 8) + ".."}</div>
            : ""}
          <div className="textWrap timeTxt" >{moment.utc(startTime).format('HH:mm')}-{moment.utc(endTime).format('HH:mm')}</div>
        </div>
      </div>);
    }
    else if ((status === 'pending' || status === 'optional') && isRequiredAll) {
      return (<div className={`F-${OwnerId}-${dateString}`} style={{ border: isOptional ? "3px solid" : "2px dashed", height: "66.5px", width: `${40.5 * duration + 3.5 * duration - 3.5 * 2}px`, borderColor: isOptional ? "#C6C9CC" : colorField, borderRadius: "4px" }}>
        <div style={{ backgroundColor: isOptional ? "transparent" : colorField, height: "62.5px", minWidth: 'fit-content', opacity: 0.4, borderRadius: "4px" }} className="template-wrap" >
          {projectName ?
            <div style={{ color: isOptional ? "black" : "white" }} className="textWrap displayNameOnPendingBooking" >{projectName}</div>
            : ""}
          <div style={{ color: isOptional ? "black" : "white" }} className="textWrap timeTxt" >{moment.utc(startTime).format('HH:mm')}-{moment.utc(endTime).format('HH:mm')}</div>
        </div>
      </div>)
    }
    else if ((status === 'pending' || status === 'optional') && !isRequiredAll) {
      return (<div className={`F-${OwnerId}-${dateString}`} style={{ border: isOptional ? "3px solid" : "2px dashed", height: "66.5px", width: "37px", borderColor: isOptional ? "#C6C9CC" : colorField, borderRadius: "4px" }}>
        <div style={{ backgroundColor: isOptional ? "transparent" : colorField, height: "62.5px", width: "33px", opacity: 0.4, borderRadius: "4px" }} className="template-wrap" >
          {projectName ?
            <div style={{ color: isOptional ? "black" : "white" }} className="textWrap displayNameOnPendingBooking" >
              {pName.length > 2
                ? pName[0]
                : projectName.length <= 5
                  ? projectName
                  : projectName.substring(0, 4)}
              <br />
              {pName.length > 2
                ? pName[1]?.substring(0, 4) + ".."
                : projectName.length <= 5
                  ? ""
                  : projectName.substring(4, 8) + ".."}
            </div>
            : ""}
          <div style={{ color: isOptional ? "black" : "white" }} className="textWrap timeTxt" >{moment.utc(startTime).format('HH:mm')}-{moment.utc(endTime).format('HH:mm')}</div>
        </div>
      </div>);
    }
    else {
      return (<></>)
    }

  }

  eventTemplate(props) {
    const { Id, status, is_required_all, is_optional, duration, StartTime, OwnerId, i, start_date, end_date, colorField, projectName, startTime, endTime } = props;

    const propStartDate = new Date(start_date);
    const propEndDate = new Date(end_date);

    var filteredStatus = this.state.alldata ? (this.state.alldata.filter((item) => { return item.Id === Id })) || []: "";
    var isRequiredAll = filteredStatus[0] ? (filteredStatus[0].is_required_all) : is_required_all;
    var isOptional = filteredStatus[0] ? (filteredStatus[0].is_optional) : is_optional;
    var bookingDuration = filteredStatus[0] ? (filteredStatus[0].duration) : duration;
    var dateString = moment(StartTime).format('YYYY-MM-DD');
    let pName = props.projectName?.split(" ");

    this.updateStars(0);

    for (var t = 1, dt = propStartDate; dt <= propEndDate; dt.setDate(dt.getDate() + 1)) {  // eslint-disable-line
      var element = document.getElementsByClassName(`${moment(dt).format('YYYY-MM-DD')}-${this.state.ownerData[i]?.id}`);
      if (element !== undefined && element.length !== 0) {
        element[0].classList.remove('pending', 'accepted', 'optional');
        element[0].classList.add(`${status}`);
      }
    }

    return (
      <>
        {this.BookingPopulation(status, isRequiredAll, bookingDuration, OwnerId, dateString, isOptional, colorField, projectName, startTime, endTime, duration, pName)}
      </>)
  }

  logoutClick() {
    localStorage.removeItem("color_code");
    localStorage.removeItem("user_type");
    localStorage.removeItem("resource_id");
    localStorage.removeItem("phone");
    localStorage.removeItem("email");
    logout();
    window.location = "/login";
  }

  onCreated() {
    this.scheduleObj.selectedDate = new Date();
    setTimeout(() => {
      this.scheduleObj.scrollTo(null, this.scheduleObj.selectedDate);
    }, 500);
  }

  select(args) {
    if (initialIndex === null) {
      initialIndex = args.element.dataset.groupIndex;
    }
    if (initialIndex !== args.element.dataset.groupIndex) {
      changeRowFlag = true;
    }
    this.mulCellDrag = true;
    let index = args.element.dataset.groupIndex;
    if (this.currentCellIndex === -1) {
      this.currentCellIndex = args.element.cellIndex;
      this.startIndex = args.element.cellIndex;
      this.isStartBusy =
        args.element.className.includes("e-disableCell") ||
          args.element.className.includes("pending") ||
          args.element.className.includes("accepted")
          ? true
          : false;
    }

    // Mark un-mark busy days
    if (
      args.element.cellIndex > this.currentCellIndex &&
      (args.element.className.includes("e-disableCell") ||
        args.element.className.includes("pending") ||
        args.element.className.includes("accepted"))
    ) {
      this.has_busy_or_booked += 1;
    } else if (
      args.element.cellIndex < this.currentCellIndex &&
      (args.element.className.includes("e-disableCell") ||
        args.element.className.includes("pending") ||
        args.element.className.includes("accepted"))
    ) {
      this.has_busy_or_booked -= 1;
    }
    // END

    // Check Cycle Completed
    if (this.startIndex === args.element.cellIndex) {
      this.has_busy_or_booked = 0;
      this.cycleCompleted = true;
      this.initialDirection = null;
      if (this.from != null) this.from = null;
      this.to = null;
    }

    if (
      this.cycleCompleted &&
      this.isStartBusy &&
      (args.element.cellIndex - this.startIndex === 1 ||
        args.element.cellIndex - this.startIndex === -1)
    ) {
      this.cycleCompleted = false;
      args.element.cellIndex > this.currentCellIndex
        ? this.has_busy_or_booked++
        : this.has_busy_or_booked--;
      this.initialDirection = null;
    }
    // End

    //direction
    if (args.element.cellIndex - this.currentCellIndex === 1) {
      this.direction = true;
      if (this.lastDirection === null) {
        this.lastDirection = true;
      }
      if (this.initialDirection === null) {
        this.initialDirection = true;
      }
      if (this.lastDirection !== this.direction) {
        this.uTurnDateCell = this.currentCellIndex + 1;
        this.directionChanged = true;
        if (
          !args.element.classList.contains("e-disableCell") &&
          !args.element.classList.contains("pending") &&
          !args.element.classList.contains("accepted") &&
          !args.element.classList.contains("optional") &&
          (args.element.previousSibling.classList.contains("e-disableCell") ||
            args.element.previousSibling.classList.contains("pending") ||
            args.element.previousSibling.classList.contains("optional") ||
            args.element.previousSibling.classList.contains("accepted"))
        ) {
          this.has_busy_or_booked++;
          if (args.element.cellIndex - 1 === this.busyFoundAt)
            this.busyFoundAt = null;
        }
      } else if (
        this.directionChanged &&
        args.element.cellIndex - this.uTurnDateCell >= 1
      ) {
        this.directionChanged = false;
      }
      this.lastDirection = true;
    } else if (args.element.cellIndex - this.currentCellIndex === -1) {
      this.direction = false;
      if (this.lastDirection === null) {
        this.lastDirection = false;
      }
      if (this.initialDirection === null) {
        this.initialDirection = false;
      }
      if (this.lastDirection !== this.direction) {
        this.uTurnDateCell = this.currentCellIndex + 1;
        this.directionChanged = true;
        if (
          !args.element.classList.contains("e-disableCell") &&
          !args.element.classList.contains("pending") &&
          !args.element.classList.contains("optional") &&
          !args.element.classList.contains("accepted") &&
          (args.element.nextSibling.classList.contains("e-disableCell") ||
            args.element.nextSibling.classList.contains("pending") ||
            args.element.nextSibling.classList.contains("optional") ||
            args.element.nextSibling.classList.contains("accepted"))
        ) {
          // && ((args.element.cellIndex - this.startIndex) != 1 && (args.element.cellIndex - this.startIndex) != -1)) {
          this.has_busy_or_booked--;
          if (args.element.cellIndex + 1 === this.busyFoundAt)
            this.busyFoundAt = null;
        }
      } else if (
        this.directionChanged &&
        args.element.cellIndex - this.uTurnDateCell <= -1
      ) {
        this.directionChanged = false;
      }
      this.lastDirection = false;
    }
    // end

    this.currentCellIndex = args.element.cellIndex;

    //date set
    if (
      this.from == null &&
      !args.element.className.includes("e-disableCell") &&
      !args.element.className.includes("pending") &&
      !args.element.className.includes("optional") &&
      !args.element.className.includes("accepted")
    ) {
      this.from = args.element.cellIndex;
      this.to = args.element.cellIndex;
      if (args.element.classList.contains("e-work-days")) {
        this.fromDate = args.element.classList[3];
        this.toDate = args.element.classList[3];
      } else {
        this.fromDate = args.element.classList[2];
        this.toDate = args.element.classList[2];
      }
    }
    if (
      this.from != null &&
      !args.element.className.includes("e-disableCell") &&
      !args.element.className.includes("pending") &&
      !args.element.className.includes("optional") &&
      !args.element.className.includes("accepted") &&
      args.element.cellIndex - this.from !== 0 &&
      this.busyFoundAt === null
    ) {
      this.to = args.element.cellIndex;
      if (args.element.classList.contains("e-work-days")) {
        this.toDate = args.element.classList[3];
      } else {
        this.toDate = args.element.classList[2];
      }
    }
    if (
      args.element.cellIndex !== this.startIndex &&
      (args.element.className.includes("e-disableCell") ||
        args.element.className.includes("pending") ||
        args.element.className.includes("optional") ||
        args.element.className.includes("accepted")) &&
      this.busyFoundAt === null
    ) {
      this.busyFoundAt = args.element.cellIndex;
    }
    if (
      args.element.cellIndex === this.busyFoundAt &&
      this.direction !== this.initialDirection
    ) {
      this.busyFoundAt = null;
    }
    if (
      args.element.cellIndex === this.from &&
      this.direction !== this.initialDirection
    ) {
      this.to = args.element.cellIndex;
      if (args.element.classList.contains("e-work-days")) {
        this.toDate = args.element.classList[3];
      } else {
        this.toDate = args.element.classList[2];
      }
    }

    if (
      this.has_busy_or_booked ||
      args.element.className.includes("e-disableCell") ||
      args.element.className.includes("pending") ||
      args.element.className.includes("optional") ||
      args.element.className.includes("accepted")
    ) {
      document
        .getElementsByClassName("e-schedule-table e-content-table")[0]
        .getElementsByTagName("tr")[index].style.cursor = "not-allowed";
    } else {
      document
        .getElementsByClassName("e-schedule-table e-content-table")[0]
        .getElementsByTagName("tr")[index].style.cursor = "auto";
    }
  }

  bookingPopupCloseHandler = (data) => {
    // if (data === "update") {
    //   setTimeout(() => {
    //     this.fetchData("");
    //   }, 20000);
    // }
    this.has_busy_or_booked = 0;
    this.currentCellIndex = -1;
    this.setState({ isBookingPopUpOpen: false, isAvailableCell: true });
    this.enableScrollingOnLeftColumn(2);
    this.updateStars(0);
  };

  handleOpenEditMenuDialog = (e) => {
    if (e !== undefined) {
      e.preventDefault();
      e.stopPropagation();
      if (e?.type === "contextmenu") {
        let tar = document.getElementById("menu-appbar1");
        tar.style.display = "block";
        let uId = e.currentTarget.getAttribute("id");
        let dName = e.currentTarget.getAttribute("displayName");
        let profession = e.currentTarget.getAttribute("profession");
        let email = e.currentTarget.getAttribute("email");
        let portfolio_link = e.currentTarget.getAttribute("portfolio_link");
        let phone = e.currentTarget.getAttribute("phone");
        this.setState({
          showEditMenuDialog: !this.state.showEditMenuDialog,
          topPos: e?.clientY.toLocaleString(),
          userId: uId,
          displayName: dName,
          profession: profession,
          email: email,
          portfolio_link: portfolio_link,
          phone: phone,
        });
        setTimeout(() => {
          this.updateStars();
        }, 1000);
      } else if (e?.type === "click") {
        let tar = document.getElementById("menu-appbar1");
        tar.style.display = "none";
        this.setState({ showEditMenuDialog: !this.state.showEditMenuDialog });
        this.fetchData(" ");
        this.enableScrollingOnLeftColumn(3);
      }
    } else {
      let tar = document.getElementById("menu-appbar1");
      tar.style.display = "none";
      this.setState({ showEditMenuDialog: !this.state.showEditMenuDialog });
      this.fetchData(" ");
      this.enableScrollingOnLeftColumn(3);
    }
  };

  cellClick(args) {
    args.cancel = true;
    let alreadyExist = this.state?.ownerData[args?.groupIndex]?.bookings.filter((item) => item.startDate === moment(args?.startTime).format("YYYY-MM-DD"))
    if (!args?.element?.classList?.contains('e-disableCell') && alreadyExist?.length === 0) {
      args?.classList?.classList?.remove('e-selected-cell')
      const userData = this.ownerData1[args.groupIndex];
      const user_info = {
        ...userData,
        id: userData.id,
        sd: moment(args.startTime),
        ed: moment(args.startTime),
        is_required_all: false,
        currency: userData.company_currency,
        busyDays: userData.freelancer_total_busy_days,
        last_confirmed_booking: userData.last_confirmed_booking,
        groupIndex: args.groupIndex,
        i: args.groupIndex
      };
      this.setState({ isBookingPopUpOpen: true, userInfo: user_info });
    }
    this.mulCellDrag = false;
    this.isStartBusy = false;
    this.cycleCompleted = false;
    this.currentCellIndex = -1;
    this.direction = null;
    this.initialDirection = null;
    this.lastDirection = null;
    this.directionChanged = false;
    this.startIndex = null;
    this.temp = null;
  }

  onNavigating(args) {
    if (args.action === "date") {
      if (
        moment(new Date()).format("YYYY-MM") ===
        moment(args.currentDate).format("YYYY-MM") &&
        this.alreadyChanged
      ) {
        let getMonth = moment(args.currentDate).format("MM");
        let getYear = moment(args.currentDate).format("YYYY");
        this.scheduleObj.selectedDate = new Date();
        args.currentDate = new Date();
        args.previousDate = new Date(moment(`${getYear}-${getMonth}-01`));
        setTimeout(() => {
          this.scheduleObj.scrollTo(null, this.scheduleObj.selectedDate);
        }, 500);
        this.alreadyChanged = false;
      } else if (
        moment(args.previousDate).format("YYYY-MM-DD") !==
        moment(args.currentDate).format("YYYY-MM-DD")
      ) {
        let getMonth = moment(args.currentDate).format("MM");
        let getYear = moment(args.currentDate).format("YYYY");
        this.scheduleObj.selectedDate = new Date(
          moment(`${getYear}-${getMonth}-01`)
        );
        args.currentDate = new Date(moment(`${getYear}-${getMonth}-01`));
        setTimeout(() => {
          this.scheduleObj.scrollTo(null, this.scheduleObj.selectedDate);
        }, 500);
        this.alreadyChanged = true;
      }
    }
  }

  memoizedScheduleComponent = memoizeOne(
    (data, ownerData) => {
      return (
        <ScheduleComponent
          style={{ position: "fixed" }}
          width="100%"
          height={window.innerHeight + 50}
          rowAutoHeight={true}
          rowWidth="110%"
          id="ScheduleComponent"
          ref={(t) => (this.scheduleObj = t)}
          quickInfoOnSelectionEnd="true"
          renderCell={this.onRenderCell.bind(this)}
          showQuickInfo={true}
          selectedDate={new Date()}
          popupOpen={this.onPopupOpen.bind(this)}
          popupClose={this.onPopupClose.bind(this)}
          group={{ resources: ["Owners"], enableCompactView: false }}
          eventSettings={{
            dataSource: data,
            enableMaxHeight: true,
            enableIndicator: false,
            template: this.eventTemplate.bind(this),
          }}
          resourceHeaderTemplate={this.resourceHeaderTemplate.bind(this)}
          dateHeaderTemplate={this.getMonthHeaderText.bind(this)}
          actionBegin={this.onActionBegin.bind(this)}
          eventRendered={this.oneventRendered.bind(this)}
          workDays={[1, 2, 3, 4, 5]}
          enablePersistence={true}
          delayUpdate={false}
          created={this.onCreated.bind(this)}
          cellClick={this.cellClick.bind(this)}
          select={this.select.bind(this)}
          enableColumnVirtualization={true}
          navigating={this.onNavigating.bind(this)}
        >
          <ResourcesDirective>
            <ResourceDirective
              field="OwnerId"
              title="Owner"
              name="Owners"
              dataSource={ownerData}
              textField="displayName"
              idField="id"
              colorField="color_code1"
            ></ResourceDirective>
          </ResourcesDirective>

          <HeaderRowsDirective>
            <HeaderRowDirective option="Month" />
            <HeaderRowDirective option="Date" />
            <HeaderRowDirective option="Hour" />
          </HeaderRowsDirective>

          <ViewsDirective>
            <ViewDirective
              option="TimelineMonth"
              displayName="Vertical TimelineMonth"
              isSelected={true}
              orientation="Vertical"
            />
          </ViewsDirective>
          <form className="example" action="">
            <svg
              className="customBtn"
              width="12"
              height="12"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.75 7C1.75 4.1005 4.1005 1.75 7 1.75C9.8995 1.75 12.25 4.1005 12.25 7C12.25 9.8995 9.8995 12.25 7 12.25C4.1005 12.25 1.75 9.8995 1.75 7ZM7 0.25C3.27208 0.25 0.25 3.27208 0.25 7C0.25 10.7279 3.27208 13.75 7 13.75C8.59376 13.75 10.0585 13.1976 11.2133 12.2739L14.7192 15.7799C15.0121 16.0728 15.487 16.0728 15.7799 15.7799C16.0728 15.487 16.0728 15.0121 15.7799 14.7192L12.2739 11.2133C13.1976 10.0585 13.75 8.59376 13.75 7C13.75 3.27208 10.7279 0.25 7 0.25Z"
                fill="#828282"
              />
            </svg>
            <input
              className="searchInput"
              type="text"
              placeholder=""
              name="search"
              onChange={this.debouncedOnChange}
            />
            <svg
              className="customFilterBtn"
              width="12"
              height="12"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.75 9.51526C0.335786 9.51526 0 9.85689 0 10.2783C0 10.6997 0.335786 11.0414 0.75 11.0414H6.38888C6.8031 11.0414 7.13888 10.6997 7.13888 10.2783C7.13888 9.85689 6.8031 9.51526 6.38888 9.51526H0.75ZM15.2492 11.0414H10.3607V12.737C10.3607 13.1584 10.0249 13.5 9.61072 13.5C9.1965 13.5 8.86072 13.1584 8.86072 12.737V10.3024C8.86047 10.2944 8.86035 10.2864 8.86035 10.2783C8.86035 10.2703 8.86047 10.2622 8.86072 10.2542V7.81956C8.86072 7.39814 9.1965 7.05651 9.61072 7.05651C10.0249 7.05651 10.3607 7.39814 10.3607 7.81956V9.51526H15.2492C15.6634 9.51526 15.9992 9.85689 15.9992 10.2783C15.9992 10.6997 15.6634 11.0414 15.2492 11.0414ZM7.25 3.72163C7.25 3.30022 7.58579 2.95859 8 2.95859L15.25 2.95859C15.6642 2.95859 16 3.30022 16 3.72163C16 4.14305 15.6642 4.48468 15.25 4.48468L8 4.48468C7.58579 4.48468 7.25 4.14305 7.25 3.72163ZM0.75 2.95859C0.335786 2.95859 0 3.30022 0 3.72163C0 4.14305 0.335786 4.48468 0.75 4.48468H4.02734V6.18041C4.02734 6.60182 4.36313 6.94345 4.77734 6.94345C5.19156 6.94345 5.52734 6.60182 5.52734 6.18041V3.74774C5.52763 3.73907 5.52777 3.73037 5.52777 3.72163C5.52777 3.7129 5.52763 3.7042 5.52734 3.69553V1.26301C5.52734 0.841589 5.19156 0.499963 4.77734 0.499963C4.36313 0.499963 4.02734 0.841589 4.02734 1.26301V2.95859H0.75Z"
                fill="#8A9099"
              />
            </svg>
          </form>
          <Inject
            services={[
              Week,
              WorkWeek,
              Month,
              Agenda,
              MonthAgenda,
              TimelineViews,
              TimelineMonth,
            ]}
          />
        </ScheduleComponent>
      );
    },
    (newArgs, lastArgs) => {
      if (
        newArgs[0].length !== lastArgs[0].length ||
        newArgs[1].length !== lastArgs[1].length
      ) {
        // new bookings  case
        return false;
      } else if (newArgs[1].length > 0 || newArgs[0].length > 0) {
        const updateRoleAndProfession = newArgs[1].some((item, index) => {
          return (
            JSON.stringify(item?.profession) !==
            JSON.stringify(lastArgs[1][index].profession) ||
            JSON.stringify(item?.portfolio_link) !==
            JSON.stringify(lastArgs[1][index].portfolio_link)
          );
        });
        const hasChanged = newArgs[0].some((item, index) => {
          return JSON.stringify(item) !== JSON.stringify(lastArgs[0][index]);
        });
        if (hasChanged || updateRoleAndProfession) {
          return false;
        } else {
          return true;
        }
      } else {
        return true; // no change, do not rerender
      }
    }
  );

  render() {
    const scheduleComponent = this.memoizedScheduleComponent(
      this.state.data,
      this.state.ownerData
    );
    return (
      <div className="client-pwa">
        {scheduleComponent}
        <PwaButton />
        {this.state.isBookingPopUpOpen && (
          <BookingPopUpModal
            isOpenModal={this.state.isBookingPopUpOpen}
            onClose={this.bookingPopupCloseHandler.bind(this)}
            isRequiredAllChecked={this.state.isRequiredAllChecked}
            userInfo={this.state.userInfo}
            scheduleObj={this.scheduleObj}
            isAvailableCell={this.state.isAvailableCell}
            parentThis={this}
            deleteBookingPayload={this.state.deleteBookingPayload}
            enableScrollingOnLeftColumn={this.enableScrollingOnLeftColumn.bind(
              this
            )}
          />
        )}
        <EditMenuDialog
          open={this.state.showEditMenuDialog}
          setOpen={this.handleOpenEditMenuDialog.bind(this)}
          topPos={this.state.topPos}
          userId={this.state.userId}
          displayName={this.state.displayName}
          profession={this.state.profession}
          fetchData={this.fetchData.bind(this)}
          email={this.state.email}
          portfolio_link={this.state.portfolio_link}
          phone={this.state.phone}
        />
      </div>
    );
  }
}
