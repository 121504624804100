import React, { useRef, useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BookingTimeRange from '../Dashboard/BookingTimeRange';
import FormControl from '@mui/material/FormControl';
import { LoadingButton } from '@mui/lab';
import { DateRangePicker } from 'rsuite';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import axios from 'axios';
import { getToken } from "../utils";
import useForm from "../Register/useForm";
import { validator, validateAllError } from "../Register/validator";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { ReactComponent as TooltipIcon } from '../../assets/Alert.svg';
import { styled } from '@mui/system';
import OptionPopup from './optionPopup';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import * as Sentry from "@sentry/react";
import {useTranslation} from "react-i18next";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import "./index.css";

// Create a styled Radio component
const CustomRadio = styled(Radio)`
&.MuiButtonBase-root {
  width: 12x;
  height: 12px;
  margin-right: 0px;
}
&.MuiFormControlLabel-root {
  margin-right: 10px;
  font-size: 14px;
}
&.MuiFormControlLabel-label {
  font-size: 14px;
}
&:hover {
  background-color: transparent;
}
`;

const style = {
  position: 'absolute',
  margin: 0,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  paddingX: "20px",
  paddingY: "12px",
};
var count = 0;
var sDate;
var dateChanged = null;
var body = {}
function numberWithCommas(x) {
  const number = x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (number.endsWith('.00')) {
    // Remove '.00' from the end of the string
    return number.slice(0, -3);
  }
  return number;
}

// TODO: Remove this code it smells bad and is not used at good ends
const CustomTextField = (props) => {
  return (
    <TextField
      label={props.label}
      margin="normal"
      variant="standard"
      required
      name={props.name}
      type={props.type}
      id={props.id}
      className={props.className}
      fullWidth={props.id === "projectName" || props.id === "location" || props.id === "description" ? true : false}
      multiline={props.id === "description" ? true : false}
      rows={props.id === "description" ? 5 : 1}
      value={props.id === "date" ? props.date : props.value}
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
      error={props.error}
      helperText={props.helperText}
      onBlur={props.handleBlur}
      onChange={props.handleChange}
      autoFocus={props.name === "location" && true}
      InputLabelProps={{ shrink: true, style: { fontSize: 18, fontFamily: "Poppins", marginTop: -7, color: "#8A9099", maxWidth: 'fit-content' } }}
      onInput={(e) => {
        if (props.name === "rate") e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9)
      }}
      InputProps={{
        disableUnderline: true,
        readOnly: (props.name === "date") ? true : false,
        autoComplete: "off"
      }}
    />
  );
};

export default function BookingPopUpModal(props) {
  const {
    isOpenModal,
    onClose,
    userInfo,
    deleteBookingPayload,
    isAvailableCell,
  } = props;
  const { sd, ed, id, displayName, countDays, is_associated_with_mint, is_location_added, last_confirmed_booking, status, is_required_all, is_optional } = userInfo;
  const currentBooking = userInfo?.bookings?.find(booking => booking.id === userInfo?.Id);
  const start = useRef(moment("09:00:00", "h:mm:ss"));
  const end = useRef(moment("18:00:00", "h:mm:ss"));
  // TODO trick the tricker because "-" is not supported by rails enum it needs to be cleaned up
  let bookingType = is_associated_with_mint ? "e-commerce" : 'other';
  if (["pending", "accepted", "optional"].includes(status)) {
    bookingType = last_confirmed_booking?.booking_type === "ecommerce" ? "e-commerce" : last_confirmed_booking?.booking_type || bookingType;
    bookingType = currentBooking?.booking_type === "ecommerce" ? "e-commerce" : currentBooking?.booking_type || bookingType;
  }
  const [missionType, setMissionType] = useState(bookingType);
  const [dayRate, setDayRate] = useState(userInfo.rate || 0);
  const [freelancerTotal, setFreelancerTotal] = useState(0);
  const [mintTotal, setMintTotal] = useState(0);
  const [warningPrice, setWarningPrice] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  let initState; var formattedDate;
  let offsetString = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const checkLocation = () => {
    if (is_location_added)
      return true;
    else
      return false;
  }

  // Not sur we should pick other or creative
  const missionTypes = [
    "e-commerce",
    "creative"
  ]

  const mappingMissionTypes = {
    'other': 0,
    "e-commerce": 1,
    "creative": 2
  }

  // TODO: use a proper API next time
  const convertion = {
    "USD": 0.27,
    "GBP": 0.22,
    "EUR": 0.26,
    "AUD": 0.42,
    "AED": 1
  }

  const isCreative = () => {
    return is_associated_with_mint && missionType === "creative";
  }

  const isEcommerce = () => {
    return is_associated_with_mint && missionType === "e-commerce";
  }

  const isExistingBooking = () => {
    return ["pending", "accepted", "optional"].includes(status);
  }

  // String translation exist to not have to go through the whole codebase to change the text
  const {t} = useTranslation('common');

  const [changedDate, setChangedDate] = React.useState([
    new Date(sd && moment(sd).format('YYYY-MM-DD').toString()),
    new Date(countDays === 1 || sd._i === ed._i ? moment(sd).format('YYYY-MM-DD').toString() : moment(ed).format('YYYY-MM-DD').toString())
  ]);

  const [values, setValues] = React.useState({
    isRequiredAllChecked: isExistingBooking() && is_required_all ? is_required_all : false,
    error: false,
    errorMsg: '',
    addLocation: checkLocation(),
    totalRate: 0,
    isLoading: false,
    isOptionBtnLoading: false,
    isCancelBtnLoading: false,
    timeError: false,
    updatedDays: countDays,
    isUpdatedDays: false,
    optionalBooking: is_optional,
    button: "book",
    optionPopupOpen: false,
  });

  // What is this .... I still can't believe we have table to give the days of the week the 
  // freelancer is booked called busyDays it doesn't make sense a simple query on booking should give a proper result
  const disableDates = (date) => {
    var busy_Days = props?.userInfo?.busyDays;
    if (busy_Days !== undefined) {
      var afterDate = null;
      const newBusyDays = busy_Days?.map((item) => (new Date(item)));
      const maxDate = new Date(Math.max(...newBusyDays));
      const maxMonth = parseInt(moment(maxDate).format('M'));
      const maxYear = parseInt(moment(maxDate).format('YYYY'));

      if (dateChanged) {
        let cD = new Date(dateChanged);
        let j = cD.setDate(cD.getDate() + 1);
        busy_Days = props?.userInfo?.busyDays;

        if (busy_Days.length > 0 && isFinite(maxMonth)) {
          for (var dt = new Date(dateChanged), et = new Date(j); dt < et; dt.setDate(dt.getDate() + 1), et.setDate(et.getDate() + 1)) {
            if (parseInt(moment(dt).format('YYYY')) > maxYear) {
              break;
            }
            else if (busy_Days?.includes(`${moment(dt).format('YYYY-MM-DD')}`)) {
              afterDate = new Date(`${moment(dt).format('YYYY-MM-DD')}`)
              break;
            }

          }
        }
      }
    }
    if (dateChanged && isBefore(date, dateChanged)) {
      return true;
    }
    else if (dateChanged && isAfter(date, afterDate)) {
      return true;
    }
    else if (busy_Days !== [] && busy_Days !== undefined && busy_Days.includes(moment(date).format('YYYY-MM-DD'))) {
      return true;
    }
    else {
      return false;
    }

  }

  const selectRange = (date) => {
    count++;
    dateChanged = date;
    disableDates(date);
    if (count % 2 === 0) {
      const oneDay = 24 * 60 * 60 * 1000;
      const updatedDays = parseInt(moment(sDate).format('M')) !== parseInt(moment(date).format('M')) ? Math.round(Math.abs((sDate - sDate) / oneDay)) : Math.round(Math.abs((sDate - date) / oneDay));
      const date1 = moment(sDate);
      const date2 = parseInt(moment(sDate).format('M')) !== parseInt(moment(date).format('M')) ? moment(sDate) : moment(date);
      const diffInDays = date2.diff(date1, 'days');
      parseInt(moment(sDate).format('M')) !== parseInt(moment(date).format('M')) ? setChangedDate([sDate, sDate]) : setChangedDate([sDate, date])
      if (updatedDays === 0) {
        setValues({ ...values, updatedDays: updatedDays + 1, isUpdatedDays: true, isRequiredAllChecked: false });
      } else {
        setValues({ ...values, updatedDays: updatedDays + 1, isUpdatedDays: true });
      }
      if (diffInDays > 0) {
        parseInt(moment(sDate).format('M')) !== parseInt(moment(date).format('M')) ? checkLastAvailableEndDate(sDate, sDate) : checkLastAvailableEndDate(sDate, date);

      }
      else {
        parseInt(moment(sDate).format('M')) !== parseInt(moment(date).format('M')) ? checkLastAvailableEndDate(sDate, sDate) : checkLastAvailableEndDate(date, sDate);
      }
      const nbDays = updatedDays + 1;
      const totalCost = getTotal(values.isRequiredAllChecked, values.isUpdatedDays, nbDays, nbDays, dayRate);
      calculate(totalCost, dayRate)
      let elem = document.getElementsByClassName('rs-picker-daterange-calendar-single')[0];
      elem.style.display = 'none';
      dateChanged = null;
    } else {
      sDate = date;
    }
  }

  if (isExistingBooking()) {
    initState = {
      rate: userInfo.rate || 0,
      projectName: userInfo.projectName,
      description: userInfo.description,
      location: userInfo.location,
      currency: userInfo.company_currency || userInfo.currency,
      isUpdated: false,
      status: status,
    };
  } else {
    initState = {
      rate: "",
      projectName: "",
      description: "",
      location: null,
      currency: userInfo.company_currency,
      addLocation: false
    };
  }

  const currencyList = {
    'AED': 'AED',
    'USD': '$',
    'GBP': '£',
    'EUR': '€',
    'AUD': 'AU$'
  };

  const { handleChange, handleBlur, state, setState, errors, setErrors } = useForm({
    initState,
    validator,
  });

  const deleteBookingHandler = async (event) => {
    if (event.target.name === "cancel") {
      setValues((values) => { return ({ ...values, isLoading: true }) })
    } else {
      setValues((values) => { return ({ ...values, isCancelBtnLoading: true }) });
    }

    if (!state.isUpdated && (countDays === 1 || sd._i === ed._i)) {
      formattedDate = sd?.format('DD MMM YYYY')
    }
    else if (!state.isUpdated && countDays > 1) {
      formattedDate = sd?.format('DD MMM YYYY') + "," + ed.format('DD MMM YYYY')
    }
    else if (state.isUpdated && values.updatedDays === 1) {
      formattedDate = moment(changedDate[0]).format('DD MMM YYYY')
    }
    else {
      formattedDate = moment(changedDate[0]).format('DD MMM YYYY') + "," + moment(changedDate[1]).format('DD MMM YYYY')
    }

    props.scheduleObj.deleteEvent(props.userInfo?.Id);
    await axios.delete(`${process.env.REACT_APP_ROUTE}/api/v1/bookings/` + deleteBookingPayload.args.data.Id, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + getToken()
      },
      data: {
        "time": deleteBookingPayload.args.data.StartTime,
        "free_id": deleteBookingPayload.args.data.OwnerId,
        "formated_date": formattedDate,
        "ids": [deleteBookingPayload.args.data.Id]
      },
      mode: 'cors',
      credentials: 'include'
    }).then((res) => {

      setValues({ ...values, isLoading: false });
      onClose("delete");
      props.parentThis.setState({ newBook: true });
      for (var dt = new Date(changedDate[0]); dt <= new Date(changedDate[1]); dt.setDate(dt.getDate() + 1)) {
        const indexToRemove = props.parentThis.ownerData1[props.userInfo.i]?.freelancer_total_busy_days?.indexOf(moment(dt).format('YYYY-MM-DD'));
        let element = document.getElementsByClassName(`${moment(dt).format('YYYY-MM-DD')}-${props.userInfo?.id}`)
        if (element !== undefined && element.length !== 0) {
          element[0].classList.remove('pending', 'e-disableCell', 'backendClass', 'e-busy-weekend', 'accepted', 'optional', 'e-selected-cell');
        }
        if (indexToRemove !== -1) {
          props.parentThis.ownerData1[props.userInfo.i]?.freelancer_total_busy_days?.splice(indexToRemove, 1);

        }
      }
      props.enableScrollingOnLeftColumn(3);
    }).catch(function (error) {
      Sentry.captureException(error);
    });
  }

  const setBookingStartTime = (time) => {
    start.current = time;
  }

  const setBookingEndTime = (time) => {
    end.current = time
  }

  const timeChangeHandler = () => {
    if (["pending", "accepted", "optional"].includes(state.status))
      setState({ ...state, isUpdated: true })
  }

  const dateChangeHandler = () => {
    dateChanged = null;
    if (["pending", "accepted", "optional"].includes(state.status))
      setState({ ...state, isUpdated: true });
  }

  const dateDisplayHandler = (dates) => {
    if (moment(dates[0]).format('DD MMM').toString() !== moment(dates[1]).format('DD MMM').toString()) {
      return (`${moment(dates[0]).format('DD MMM').toString()} - ${moment(dates[1]).format('DD MMM').toString()}`)
    }
    else {
      return (`${moment(dates[0]).format('DD MMM').toString()}`)
    }
  }

  // TODO: There are a lot of options to disable some dates in the calendar
  // no need to reinvent the wheel with that kind of job
  const checkLastAvailableEndDate = (start, end) => {
    let i = parseInt(moment(start).format('DD'));
    let j = parseInt(moment(end).format('DD'));
    let year = parseInt(moment(start).format('YYYY'));
    let month = parseInt(moment(start).format('MM'));
    let busy_Days = props?.userInfo?.busyDays;
    if (parseInt(moment(start).format('M')) !== parseInt(moment(end).format('M'))) {
      const oneDay = 24 * 60 * 60 * 1000;
      const currentDateString = `${year}-${[1, 2, 3, 4, 5, 6, 7, 8, 9].includes(month) ? `0${month}` : month}-${[1, 2, 3, 4, 5, 6, 7, 8, 9].includes(i) ? `0${i}` : i}`
      const updatedDays = Math.round(Math.abs((start - new Date(currentDateString)) / oneDay));
      setChangedDate([start, new Date(`${year}-${month}-${i}`)])
      setValues({ ...values, updatedDays: updatedDays + 1, });
      return
    }

    for (let s = i + 1; s < j; s++) {

      if (busy_Days?.includes(`${year}-${[1, 2, 3, 4, 5, 6, 7, 8, 9].includes(month) ? `0${month}` : month}-${[1, 2, 3, 4, 5, 6, 7, 8, 9].includes(s) ? `0${s}` : s}`)) {
        const oneDay = 24 * 60 * 60 * 1000;
        const currentDateString = `${year}-${[1, 2, 3, 4, 5, 6, 7, 8, 9].includes(month) ? `0${month}` : month}-${[1, 2, 3, 4, 5, 6, 7, 8, 9].includes(s) ? `0${s - 1}` : s - 1}`
        const updatedDays = Math.round(Math.abs((start - new Date(currentDateString)) / oneDay));
        setChangedDate([start, new Date(`${year}-${month}-${s - 1}`)])
        setValues({ ...values, updatedDays: updatedDays + 1, isUpdatedDays: true });
        return;
      }
    }
  }

  const checkRequiredAll = () => {
    if ((state.isUpdated || values.isUpdatedDays) && values.updatedDays > 1) {
      return true;
    }
    else if (!values.isUpdatedDays && countDays > 1) {
      return true;
    }
    else {
      return false;
    }
  }

  const getTotal = (requiredAll, isUpdatedDays, updatedDays, countDays, rate) => {
    if (requiredAll && isUpdatedDays && updatedDays > 1) {
      return rate * updatedDays;
    } else if (requiredAll && countDays > 1) {
      return rate * countDays;
    } else {
      return rate;
    }
  }

  const handleChangeType = (event) => {
    const { value } = event.target;
    if (isExistingBooking()) {
      state.isUpdated = true;
    }
    setMissionType(value);
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    if (dayRate < 500  && isCreative()){
      setErrorMessage(true);
      return;
    }
    const data = Object.fromEntries(new FormData(event.target).entries());
    const { projectName, description, location } = data;
    var locationCheck;

    if (values.addLocation === true && (location === "" || location === undefined))
      locationCheck = false;
    else
      locationCheck = true;

    if ((!is_associated_with_mint || isCreative()) && !state?.projectName) {
      setErrors({ ...errors, projectName: "Project name is required" });
      return;
    }

    setErrors();

    let allEntered = is_associated_with_mint ?
      !values.timeError && locationCheck !== false && (!values.addLocation || Object.values(errors).every(el => el === undefined)) :
      locationCheck !== false && !values.timeError && (!values.addLocation || Object.values(errors).every(el => el === undefined))

    if (!state.isUpdated && (countDays === 1 || sd._i === ed._i)) {
      formattedDate = sd?.format('DD MMM YYYY')
    }
    else if (!state.isUpdated && countDays > 1) {
      formattedDate = sd?.format('DD MMM YYYY') + "," + ed.format('DD MMM YYYY')
    }
    else if (state.isUpdated && values.updatedDays === 1) {
      formattedDate = moment(changedDate[0]).format('DD MMM YYYY')
    }
    else {
      formattedDate = moment(changedDate[0]).format('DD MMM YYYY') + "," + moment(changedDate[1]).format('DD MMM YYYY')
    }
    body = {
      title: "Sample",
      project_name: projectName,
      projectName: projectName,
      description: description,
      is_required_all: values.isRequiredAllChecked,
      duration: values.isUpdatedDays ? values.updatedDays : countDays,
      price: dayRate,
      rate: dayRate,
      total_price: getTotal(values.isRequiredAllChecked, values.isUpdatedDays, values.updatedDays, countDays, dayRate),
      price_type: 1,
      location: state.location,
      user_id: id,
      profile_id: id,
      start_time: start.current.format("HH:mm"),
      end_time: end.current.format("HH:mm"),
      start_date: moment(changedDate[0]).format('YYYY-MM-DD'),
      end_date: moment(changedDate[0]).format('YYYY-MM-DD') !== moment(changedDate[1]).format('YYYY-MM-DD') ? moment(changedDate[1]).format('YYYY-MM-DD') : moment(changedDate[0]).format('YYYY-MM-DD'),
      currency: state.currency,
      is_location_added: values.addLocation,
      status: status === "accepted" ? "pending" : status,
      formatted_Date: formattedDate,
      is_optional: values.button === "book" ? false : values.optionalBooking,
      previous_booking: props.userInfo?.Id,
      time_zone: offsetString,
      booking_type: mappingMissionTypes[missionType],
    }

    if (allEntered) {
      if (values.button === "book") {
        body.status = undefined;
        setValues({ ...values, isLoading: true });
      } else if (values.button === "option") {
        setValues({ ...values, isOptionBtnLoading: true });
        body.status = "optional";
        body.is_optional = true;
      } else if (values.button === "update") {
        setValues({ ...values, isLoading: true });
      }
      else {
        setValues({ ...values, isCancelBtnLoading: true });
      }

      const bookingData = {
        "booking": body
      }

      if (state.isUpdated) {
        props.scheduleObj.deleteEvent(props.userInfo?.Id);
        if (values.isUpdatedDays) {
          for (var arr = [], dt = new Date(props.userInfo?.StartTime); dt <= new Date(props.userInfo?.EndTime); dt.setDate(dt.getDate() + 1)) {
            const indexToRemove = props.parentThis.ownerData1[props.userInfo.i]?.freelancer_total_busy_days?.indexOf(moment(dt).format('YYYY-MM-DD'));
            let element = document.getElementsByClassName(`${moment(dt).format('YYYY-MM-DD')}-${props.userInfo?.id}`)
            if (element !== undefined && element.length !== 0) {
              element[0].classList.remove('pending', 'e-disableCell', 'backendClass', 'e-busy-weekend', 'accepted', 'optional', 'e-selected-cell');
            }
            if (indexToRemove !== -1) {
              props.parentThis.ownerData1[props.userInfo.i]?.freelancer_total_busy_days.splice(indexToRemove, 1);

            }
          }
        }
        for (var arr = [], dt = new Date(changedDate[0]); dt <= new Date(changedDate[1]); dt.setDate(dt.getDate() + 1)) {  // eslint-disable-line
          props.parentThis.ownerData1[props.userInfo.i]?.freelancer_total_busy_days?.push(moment(dt).format('YYYY-MM-DD'))
        }
      }
      if (values.optionalBooking) {
        props.scheduleObj.deleteEvent(props.userInfo?.Id);
      }

      await axios.post(`${process.env.REACT_APP_ROUTE}/api/v1/bookings`, bookingData, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + getToken()
        },
        mode: 'cors',
        credentials: 'include'
      }).then(({ data }) => {
        sessionStorage.setItem("inApp", "true");
        setValues({ ...values, isLoading: false });
        onClose("update");
        let missingObj = {};
        if (!body.is_required_all && body.duration > 1) {
          data.bookings.map((booking) => {  // eslint-disable-line
            missingObj = {
              Id: booking.id, Subject: booking.title,
              status: booking.status,
              StartTime: new Date(booking.startDate),
              EndTime: new Date(booking.endDate),
              colorField: booking.color_code,
              is_manager: booking.is_manager,
              IsAllDay: true,
              OwnerId: booking.profile_id,
              i: props.userInfo.groupIndex,
              projectName: booking.project_name,
              description: booking.description,
              location: booking.location,
              rate: booking.rate,
              totalRate: booking.total_rate,
              start_date: booking.startDate,
              end_date: booking.endDate,
              startTime: booking.calender_start_time,
              endTime: booking.calender_end_time,
              currency: booking.currency,
              duration: booking.duration,
              is_associated_with_mint: is_associated_with_mint ? true : false,
              displayName: booking.client_name,
              is_location_added: booking.is_location_added,
              is_required_all: booking.is_required_all,
              is_optional: booking.is_optional,
            }
            props.scheduleObj.addEvent(missingObj);
          })
        } else {
          missingObj = {
            Id: data.bookings[0].id, Subject: data.bookings[0].title,
            status: data.bookings[0].status,
            StartTime: new Date(body.start_date),
            EndTime: new Date(body.end_date),
            colorField: data.bookings[0].color_code,
            is_manager: data.bookings[0].is_manager,
            IsAllDay: true,
            OwnerId: data.bookings[0].profile_id,
            i: props.userInfo.groupIndex,
            projectName: data.bookings[0].project_name,
            description: data.bookings[0].description,
            location: data.bookings[0].location,
            rate: data.bookings[0].rate,
            totalRate: data.bookings[0].total_rate,
            start_date: body.start_date,
            end_date: body.end_date,
            startTime: data.bookings[0].calender_start_time,
            endTime: data.bookings[0].calender_end_time,
            currency: data.bookings[0].currency,
            duration: data.bookings[0].duration,
            is_associated_with_mint: is_associated_with_mint ? true : false, // Magical code is_associated_with_mint is a boolean so we check if false is false then we return false and true is true then we return true...
            displayName: data.bookings[0].client_name,
            is_location_added: data.bookings[0].is_location_added,
            is_required_all: data.bookings[0].is_required_all,
            is_optional: data.bookings[0].is_optional,
          }
          props.scheduleObj.addEvent(missingObj);
        }
        for (var arr = [], dt = new Date(changedDate[0]); dt <= new Date(changedDate[1]); dt.setDate(dt.getDate() + 1)) {  // eslint-disable-line
          let element = document.getElementsByClassName(`${moment(dt).format('YYYY-MM-DD')}-${props.userInfo?.id}`)
          if (element !== undefined && element.length !== 0) {
            body.status === 'optional' ? element[0].classList.add('optional') : element[0].classList.add('pending');
          }
          props.parentThis.ownerData1[props.userInfo.i]?.freelancer_total_busy_days.push(moment(dt).format('YYYY-MM-DD'))
        }
        if (body.status === undefined) {
          props.parentThis.ownerData1[props.userInfo.i].last_confirmed_booking = data.last_confirmed_booking
        }
        props.enableScrollingOnLeftColumn(3);
      }).catch(function (error) {
        setErrors({ ...errors, serverError: error?.response?.data?.error });
        Sentry.captureException(error?.response?.data);
        return;
      });
    } else {
      let fieldArray = !is_associated_with_mint ? ['rate', 'addLocation',] : ['addLocation',];
      validateAllError(fieldArray, body, setErrors);
    }
  }

  const handleInputRate = (event) => {
    event.preventDefault();
    let { value } = event.target;
    const sanitizedValue = value.replace(/^0+/, '');
    if (isExistingBooking()) {
      state.isUpdated = true;
    }
    setDayRate(sanitizedValue || 0);
    const totalCost = getTotal(values.isRequiredAllChecked, values.isUpdatedDays, values.updatedDays, countDays, sanitizedValue);
    calculate(totalCost, sanitizedValue);
  }

  const convert = (price, initial = false) => {
    if (initial) return price * convertion[state.currency];
    return price / convertion[state.currency];
  }

  const handleAllDays = (isRequiredAllChecked) => {
    const totalCost = getTotal(isRequiredAllChecked, values.isUpdatedDays, values.updatedDays, countDays, dayRate);
    calculate(totalCost, dayRate);
  }

  const calculate = (totalCost, rate) => {
    const total = convert(parseFloat(totalCost));
    if (rate < 500 && isCreative()){
      setErrorMessage(true);
      return;
    }
    setErrorMessage(false);
    if (total < 2000 && isCreative()) {
      setWarningPrice(true);
      setFreelancerTotal(parseFloat(totalCost).toFixed(2));
      setMintTotal(convert(300, true).toFixed(2));
      return;
    }
    setFreelancerTotal(parseFloat(totalCost).toFixed(2));
    const mintTotal = (parseFloat(totalCost) * 0.15).toFixed(2);
    setMintTotal(mintTotal);
    setWarningPrice(false);
  }

  useEffect(() => {
    if (isExistingBooking()) {
      const totalCost = getTotal(values.isRequiredAllChecked, values.isUpdatedDays, values.updatedDays, countDays, dayRate);
      calculate(totalCost, dayRate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal open={isOpenModal} onClose={onClose}>
        <Stack sx={style} className="bookingModal" component="form" noValidate onSubmit={onSubmit}>
          { errors?.serverError && <Alert severity="error" className='datesLabel'> {errors?.serverError} </Alert> }
          <CloseIcon onClick={onClose} className='bookingCloseButton' color='action' />
          <Typography className='bookingName'>{displayName}</Typography>

          { is_associated_with_mint && (
            <div className='rowAlign'>
              <FormControl className='auto'>
                <RadioGroup
                  defaultValue={missionType}
                  name="rate"
                  row
                  onChange={handleChangeType}
                >
                  { missionTypes.map((missionType) => (
                    <FormControlLabel value={missionType}
                      control={
                        <CustomRadio />
                      }
                      label={missionType}/>
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          )}

          <div className='timeSettingFields'>
            <Box className="columnAlign">
              <p className='datesLabel' style={{ position: 'absolute' }} >Dates</p>
              <DateRangePicker
                showOneCalendar
                value={changedDate}
                renderValue={(changedDate) => <div>{dateDisplayHandler(changedDate)}</div>}
                renderTitle={(changedDate) => <div><span className='monthName'>{moment(changedDate).format('MMMM').toString()}</span> <span className='monthNameSub'>{moment(changedDate).format('YYYY').toString()}</span></div>
                }
                editable={false}
                onChange={(dates) => {
                }}
                onClick={dateChangeHandler}
                disabledDate={date => disableDates(date)}
                onSelect={(date) => selectRange(date)}
                onOk={(dates) => checkLastAvailableEndDate(dates[0], dates[1])}
                onOpen={() => {
                  dateChanged = null;
                }}
                className='bookingDateRangePicker'
                style={{ zIndex: 10000, marginTop: '31px' }} />
            </Box>
            <Box className="customTimeSelector">
              <p className='hoursLabel' >Hours</p>
              <BookingTimeRange
                isTimeUpdated={timeChangeHandler}
                setBookingStartTime={setBookingStartTime}
                setBookingEndTime={setBookingEndTime}
                userInfo={userInfo}
                setError={(error) => { setValues({ ...values, timeError: error }) }}
              />
            </Box>
          </div>
          <div className='errorRow'>
            <p className='errorMsg'>{ }</p>
            <p className='errorMsg'>Invalid Time!</p>
          </div>

          {checkRequiredAll() &&
            <FormControlLabel
              control={
                <Checkbox
                  value="requiredAll"
                  checked={values.isRequiredAllChecked}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                  onChange={(e) => {
                    setValues({ ...values, isRequiredAllChecked: !values.isRequiredAllChecked })
                    handleChange(e)
                    handleAllDays(!values.isRequiredAllChecked)
                  }}
                  onClick={(e) => {
                    setValues({ ...values, isRequiredAllChecked: !values.isRequiredAllChecked })
                    handleChange(e)
                    handleAllDays(!values.isRequiredAllChecked)
                  }}
                  className='addLocationCheckbox'
                />
              }
              label={
                <Typography className='bookingCheckBox' >Required all {values.isUpdatedDays ? values.updatedDays : countDays} days</Typography>
              }
            />
          }

          {isEcommerce() ?
            (<div></div>) : 
            (
              <Grid container spacing={0} className="project-rates">
                <Grid xs={6}>
                  <Grid container spacing={0}>
                    <Grid xs={12}>
                      <span className='datesLabel'>{ t(`booking.dayRate`) }</span>
                    </Grid>
                    <Grid xs={12}>
                      <TextField
                        type="number"
                        value={dayRate}
                        name="rate"
                        onInput={handleInputRate}
                        className="ratePerDayStyle"
                        error={Boolean(errorMessage)}
                        helperText={errorMessage ? "Minimum is AED 500" : ""}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <span style={{ position: 'absolute', color: '#3F434A', marginLeft: state.currency === 'AED' ? '10px' : '20px', fontSize: '14px', marginTop: '5px' }}>{currencyList[state.currency]}</span>
                            </InputAdornment>
                          ),
                          pattern: '[0-9]*'
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {isCreative() && (
                  <Grid xs={5} className='all-fees-descriptions'>
                    <Grid container spacing={0}>
                      <Grid item xs={5} md={5}>
                        <p className={`totalLabel`}>
                          { t('booking.total')}:
                        </p>
                      </Grid>
                      <Grid item xs={7} md={7}>
                        <span className={`totalLabelnumber`}> {currencyList[state.currency]} { numberWithCommas(parseFloat(freelancerTotal) + parseFloat(mintTotal)) }</span>
                      </Grid>
                      <Grid item xs={5} md={5} className="small-margin">
                        <span className={`rate-content ${warningPrice ? 'red' : ''}`}>{ t('booking.Agency') }:</span>
                      </Grid>
                      <Grid item xs={7} md={7} className="small-margin">
                        <span className={`rate-content ${warningPrice ? 'red' : ''}`} >{currencyList[state.currency]} {numberWithCommas(mintTotal || 0)}</span>
                      </Grid>
                      <Grid item xs={5} md={5}>
                        <span className={`rate-content ${warningPrice ? 'red' : ''}`}>{ displayName.split(" ")[0] }: </span>
                      </Grid>
                      <Grid item xs={7} md={7}>
                        <span className={`rate-content ${warningPrice ? 'red' : ''}`} > {currencyList[state.currency]} {numberWithCommas(freelancerTotal || 0)}</span>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {values.isRequiredAllChecked && !is_associated_with_mint && (
                  <Grid xs={6}>
                    <Grid xs={12}>
                      <p className='totalLabel transparent'>{ t('booking.total')} </p>
                    </Grid>
                    <Grid xs={12}>
                      <Typography className="bookingTotal"> { t('booking.total')}: {currencyList[state.currency]} {dayRate ? numberWithCommas(values.isRequiredAllChecked ? dayRate * (values.isUpdatedDays ? values.updatedDays : countDays) : dayRate) : 0} </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )
          }

          { !isEcommerce() && (
            <CustomTextField
              id="projectName"
              name="projectName"
              className="bookingProjectName"
              placeholder="Project Name"
              value={state.projectName}
              handleChange={handleChange}
            />
          )}

          <CustomTextField
            id="description"
            name="description"
            className={!(is_associated_with_mint & missionType === 'e-commerce') ? "bookingDescription" : "bookingDescriptionSingle"}
            placeholder="Description"
            value={state.description}
            handleChange={handleChange}
            helperText={errors?.projectName || errors?.description ? 'Project Name is required' : ''}
          />

          <FormControlLabel
            control={
              <Checkbox
                name="addLocation"
                checked={values.addLocation}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                onChange={(e) => {
                  setValues({ ...values, addLocation: !values.addLocation });
                  handleChange(e)
                }}
                onClick={(e) => {
                  setValues({ ...values, addLocation: !values.addLocation });
                  handleChange(e)
                }}
                className='addLocationCheckbox'
              />
            }
            label={
              <Typography className='bookingCheckBox'>Add Location</Typography>
            }
          />

          {
            values.addLocation && <CustomTextField
              label="Location"
              id="location"
              name="location"
              fullWidth="true"
              value={state.location}
              className="bookingInputField bookingLocation"
              handleBlur={handleBlur}
              handleChange={handleChange}
              helperText={errors?.location}
            />
          }

          {isAvailableCell && status !== "pending" && status !== "accepted" && (state.isUpdated === undefined || state.isUpdated === false) && (status === undefined || status === "optional") ?
            <LoadingButton
              name="book"
              onClick={() => setValues((values) => { return ({ ...values, button: "book" }) })}
              type="submit"
              fullWidth
              variant="contained"
              className='bookBtn'
              loading={values.isLoading}
              sx={{ mt: 3, mb: 2 }}
            >
              <Typography className="bookBtnText">Book</Typography>
            </LoadingButton> :
            state.isUpdated ?
              <LoadingButton
                onClick={() => setValues((values) => { return ({ ...values, button: "update" }) })}
                type="submit"
                fullWidth
                variant="contained"
                className='bookBtn'
                loading={values.isLoading}
                sx={{ mt: 3, mb: 2 }}

              >
                <Typography className="bookBtnText">Update</Typography>
              </LoadingButton> :

              !values.optionalBooking &&
              <LoadingButton
                name="cancel"
                fullWidth
                variant="contained"
                className='bookingCancelBtn bookBtnText'
                loading={values.isLoading}
                sx={{ mt: 3, mb: 2 }}
                onClick={(e) => { deleteBookingHandler(e) }}
              >
                Cancel
              </LoadingButton>}

          <div className='optionBtnContainer'>
            {values.optionalBooking ?
              status === "optional" &&
              <LoadingButton
                name="cancelOption"
                className="cancelOptionBtn"
                onClick={(e) => { deleteBookingHandler(e) }}
                loading={values.isCancelBtnLoading}
              >
                Cancel Option
              </LoadingButton>
              :
              status !== "pending" && status !== "accepted" &&
              <>
                <LoadingButton
                  name="option"
                  onClick={() => setValues((values) => { return ({ ...values, button: "option" }) })}
                  type="submit"
                  className="optionBtn"
                  loading={values.isOptionBtnLoading}
                >
                  Option
                </LoadingButton>
                <div className='optionInfoBtn'>
                  <TooltipIcon onClick={() => setValues((values) => { return ({ ...values, optionPopupOpen: true }) })} />
                </div>
              </>
            }
          </div>
        </Stack >
      </Modal >

      <OptionPopup open={values.optionPopupOpen} setOpen={setValues} displayName={displayName} />
    </>
  );
}