import React, { Suspense } from 'react'
import Agency from "./agency";
import Navbar from './Navbar';
import ClientPwa from './ClientPwa';
import { Box } from '@mui/material';
import Pusher from 'pusher-js';

const Client = React.lazy(() => import('./client'));

export default class Dashboard extends React.Component {

  constructor() {
    super(...arguments);
    this.state = {
      newNotificationArrived: false,
    }
    var that = this;
    var pusher = new Pusher(process.env.REACT_APP_PUSHER_TOKEN, {
      cluster: process.env.REACT_APP_CLUSTER
    });
    var channel = pusher.subscribe('booking-channel');
    this.bindPusherEvents(that, channel);
    this.handleNewNotificationArrived = this.handleNewNotificationArrived.bind(this);
  }

  bindPusherEvents = (that, channel) => {
    var currenUser = localStorage.getItem('resource_id');

    channel.bind(`invitation_response/${currenUser}`, function (data) {
      that.handleNewNotificationArrived(true);
    });

    channel.bind(`client-respond-event/${currenUser}`, function (data) {
      that.handleNewNotificationArrived(true);
    });

    channel.bind(`delete-event/${currenUser}`, function (data) {
      that.handleNewNotificationArrived(true);
    });

    channel.bind(`freelancer-unavailable/${currenUser}`, function (data) {
      that.handleNewNotificationArrived(true);
    });
  }

  handleNewNotificationArrived(state) {
    this.setState({ newNotificationArrived: state });
  };

  render() {
    var userType = localStorage.getItem('user_type');
    if (userType === null) window.location.href = "/landing";
    return <>
      {userType === "Company" && <Agency />}
      {userType === "Firm" && <>
        <Box sx={{ zIndex: 100, position: 'absolute', right: '10px' }} ><Navbar newNotificationArrived={this.state.newNotificationArrived} handleNewNotificationArrived={this.handleNewNotificationArrived} /></Box>
        <Suspense fallback={<span>Loading...</span>}>
          <Box >
            {
              window.innerWidth > 900 ? <Client /> : <ClientPwa />
            }
          </Box>
        </Suspense>
      </>}
    </>
  }
}