import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const OptionPopup = ({ open, setOpen, displayName }) => {
  return (
    <>
      <Dialog
        open={open}
        className="OptionPopup"
        onClose={() => setOpen({ open: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className='optionPopupTitle'>
          {`Option ${displayName}`}
        </DialogTitle>
        <DialogContent className='optionPopupContentContainer'>
          <DialogContentText>
            <p className='optionPopupContent'>
              Optioning someone lets them know you may require them, but you cannot confirm it just yet. The option will be displayed in the freelancers calendar until it’s either cancelled or booked by you.
            </p>

            <p className='optionPopupContent'>
              The freelancer reserves the right decline the option or accept another booking they may receive on the same date.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions className='optionPopupBtnContainer'>
          <Button className='optionPopupBtn' onClick={() => setOpen({ open: false })} autoFocus>
            Understood
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default OptionPopup;