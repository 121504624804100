import React, { useState, useEffect } from 'react';
import TimeRange from "react-time-range";
import moment from 'moment';

const BookingTimeRange = (props) => {
  const {
    setBookingStartTime,
    setBookingEndTime,
    isTimeUpdated,
    userInfo,
  } = props;
  var start = moment("09:00:00", "h:mm:ss"), end = moment("18:00:00", "h:mm:ss");
  const [startTime, setStartTime] = useState(start);
  const [endTime, setEndTime] = useState(end);

  var prePopulatedStartTime = moment.utc(userInfo.status === "pending" || userInfo.status === "accepted" || userInfo.status === "optional" ? userInfo.startTime : userInfo.last_confirmed_booking?.start_time).format("HH:mm:ss");
  var prePopulatedEndTime = moment.utc(userInfo.status === "pending" || userInfo.status === "accepted" || userInfo.status === "optional" ? userInfo.endTime : userInfo.last_confirmed_booking?.end_time).format("HH:mm:ss");

  useEffect(() => {
    if (userInfo.last_confirmed_booking || userInfo.status === "pending" || userInfo.status === "accepted" || userInfo.status === "optional") {
      start = moment(prePopulatedStartTime, "h:mm:ss");  // eslint-disable-line
      end = moment(prePopulatedEndTime, "h:mm:ss");  // eslint-disable-line

      //Current Component State Update
      setStartTime(start);
      setEndTime(end);

      //Parent Component State Update
      setBookingStartTime(start);
      setBookingEndTime(end);
    }
  }, []);

  const startTimeChange = (e) => {
    setStartTime(moment(e.startTime));
    setBookingStartTime(moment(e.startTime));
    isTimeUpdated();
    errorHandling('START_TIME', e.startTime);
  };

  const endTimeChange = (e) => {
    setEndTime(moment(e.endTime));
    setBookingEndTime(moment(e.endTime));
    isTimeUpdated();
    errorHandling('END_TIME', e.endTime);
  };

  const errorHandling = (type, time) => {
    let showError = false;

    switch (type) {
      case 'START_TIME':
        showError = moment(time).isAfter(endTime) || moment(time).isSame(endTime) ? true : false;
        // console.log("Start time: ", moment(time).format("hh:mm A"), "end time: ", endTime.format("hh:mm A"), "moment(time).isAfter(endTime) ", moment(time).isAfter(endTime));
        break;
      case 'END_TIME':
        showError = moment(time).isBefore(startTime) || moment(time).isSame(startTime) ? true : false;
        if (moment(time).format("hh:mm A") === "12:00 AM") showError = false
        // console.log("Start time: ", startTime.format("hh:mm A"), "end time: ", moment(time).format("hh:mm A"), "moment(time).isBefore(startTime) ", moment(time).isBefore(startTime));
        break;
      default:
    }

    setTimeout(() => {
      if (showError) {
        document.getElementsByClassName("errorRow")[0].style.display = "flex";
        props.setError(true);
      } else {
        document.getElementsByClassName("errorRow")[0].style.display = "none";
        props.setError(false);
      }
    }, 100);
  }

  return (
    <TimeRange
      startMoment={startTime}
      endMoment={endTime}
      onStartTimeChange={startTimeChange}
      onEndTimeChange={endTimeChange}
      startLabel=" "
      endLabel="-"
      use24Hours
      className="timeRange"
      endTimeError="Invalid Time"
      sameIsValid={false}
    />
  );
}

export default BookingTimeRange