import * as React from 'react';
import { ScheduleComponent, ViewsDirective, ViewDirective, MonthAgenda, TimelineViews, ResourcesDirective, ResourceDirective, Inject, TimelineMonth, HeaderRowsDirective, HeaderRowDirective } from '@syncfusion/ej2-react-schedule';
import axios from 'axios';
import memoizeOne from 'memoize-one';
import debounce from 'lodash/debounce';
import { getToken, logout } from "../utils"
import { L10n } from '@syncfusion/ej2-base';
import { Week, WorkWeek, Month, Agenda } from '@syncfusion/ej2-react-schedule';
import "./agency.scss";
//Notifications
import 'react-notifications/lib/notifications.css';
//Pusher for notifications
import Pusher from 'pusher-js';
import moment from 'moment';
import BookingDetailsDialog from '../BookingDetailsDialog';
import mintLogo from "../../assets/MiNT_LOGO1.svg";
import CustomSkeleton from '../Skeleton';

L10n.load({
  'en-US': {
    'schedule': {
      'newEvent': 'Schedule Appointment',
    },
  }
});

var stars = {};
var keyValue = [];
var isBusy = false;
var yearFlag = false;  // eslint-disable-line no-unused-vars
var yearData = null;

export default class Agency extends React.Component {
  scheduleObj = {};
  constructor() {
    super(...arguments);
    this.state = {
      data: [],
      all_data: [],
      disableDays: [],
      isDialogVisible: false,
      ownerData: [],
      starsonSearch: true
    }
    this.filterBooking = React.createRef();
    this.selectedDate = React.createRef();
    this.isAssocitedWithMint = React.createRef();
    var that = this;
    var pusher = new Pusher(process.env.REACT_APP_PUSHER_TOKEN, {
      cluster: process.env.REACT_APP_CLUSTER
    });

    var channel = pusher.subscribe('booking-channel');
    this.enableScrollingOnLeftColumn = this.enableScrollingOnLeftColumn.bind(this);
    this.debouncedOnChange = debounce(this.onChange.bind(this), 500);
    this.bindPusherEvents(that, channel);
  }

  setSliderPostion = () => {
    setTimeout(() => {
      window.scrollTo({ top: parseInt(sessionStorage.getItem("agency_Y_Pos")), left: sessionStorage.getItem("agency_X_Pos"), behavior: 'smooth' });
    }, 1700)
  }

  componentDidMount = () => {
    document.title = "Flxibl | " + localStorage.getItem('agency');
    if (window.location.origin.includes('https://www.flxibl.io')) {
      window.location.replace("https://www.flxibl.com" + window.location.pathname);
    }
    this.enableScrollingOnLeftColumn(7);
    this.fetchData("");
    setTimeout(() => {
      document.querySelector('.e-content-wrap').addEventListener("scroll", (event) => {
        sessionStorage.agency_X_Pos = document.querySelector('.e-content-wrap').scrollLeft;
        sessionStorage.agency_Y_Pos = document.querySelector('.e-content-wrap').scrollTop;
      });
    }, 1700);
  }

  enableScrollingOnLeftColumn(sec) {
    setTimeout(() => {
      var scrollableDiv = document.querySelector('.e-resource-column-wrap');
      var targetDiv = document.querySelector('.e-content-wrap');

      scrollableDiv.addEventListener('scroll', () => {
        var pos = scrollableDiv.scrollTop;
        targetDiv.scrollTop = pos;
      });
    }, sec * 1000);
  }

  bindPusherEvents = (that, channel) => {
    var currenUser = localStorage.getItem('resource_id');
    channel.bind(`create-event/${currenUser}`, function (data) {
      // NotificationManager.success(data.message, data.title, 10000);
      that.fetchData(document.querySelector('.searchInput').value);
    });

    channel.bind(`agency-respond-event/${currenUser}`, function (data) {
      // NotificationManager.success(data.message, data.title, 5000);
      that.fetchData(document.querySelector('.searchInput').value);
    });

    channel.bind(`busy-event/${currenUser}`, function (data) {
      // NotificationManager.success(data.message, data.title, 5000);
      that.fetchData(document.querySelector('.searchInput').value);
    });

    channel.bind(`delete-event/${currenUser}`, function (data) {
      // NotificationManager.success(data.message, data.title, 5000);
      that.fetchData(document.querySelector('.searchInput').value);
    });
  }
  unbindPusherEvents = (channel) => {
    var currenUser = localStorage.getItem('resource_id');
    channel.unbind(`create-event/${currenUser}`)
    channel.unbind(`busy-event/${currenUser}`)
    channel.unbind(`agency-respond-event/${currenUser}`)
    channel.unbind(`delete-event/${currenUser}`)
  }
  fetchData = async (value) => {
    var that = this;
    let url = `${process.env.REACT_APP_ROUTE}/api/v1/networks`
    if (value.length > 0)
      url = `${process.env.REACT_APP_ROUTE}/api/v1/networks?q=` + value
    axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + getToken()
      },
      mode: 'cors',
      credentials: 'include'
    })
      .then(function (response) {
        var data = []

        response.data.map((a, index) => {  // eslint-disable-line
          if (a.bookings)
            a.bookings.map(booking => {  // eslint-disable-line
              if (data.length >= 1) {
                if (!(moment(data[data.length - 1].StartTime).format("YYYY-MM-DD") === booking.startDate && data[data.length - 1].OwnerId === booking.profile_id)) {
                  data.push({
                    Id: booking.id, Subject: booking.title,
                    status: booking.status,
                    StartTime: new Date(booking.startDate),
                    EndTime: new Date(booking.endDate),
                    colorField: booking.color_code,
                    is_manager: booking.is_manager,
                    IsAllDay: true,
                    OwnerId: booking.profile_id,
                    i: index,
                    projectName: booking.project_name,
                    title: booking.title,
                    description: booking.description,
                    location: booking.location,
                    rate: booking.rate,
                    totalRate: booking.total_rate,
                    start_date: booking.startDate,
                    end_date: booking.endDate,
                    startTime: booking.booking_start_time,
                    endTime: booking.booking_end_time,
                    currency: booking.currency,
                    duration: booking.duration,
                    is_associated_with_mint: a.is_associated_with_mint,
                    displayName: a.displayName,
                    is_location_added: booking.is_location_added,
                    is_required_all: booking.is_required_all,
                  })
                }
              }
              else {
                data.push({
                  Id: booking.id, Subject: booking.title,
                  status: booking.status,
                  StartTime: new Date(booking.startDate),
                  EndTime: new Date(booking.endDate),
                  colorField: booking.color_code,
                  is_manager: booking.is_manager,
                  IsAllDay: true,
                  OwnerId: booking.profile_id,
                  i: index,
                  projectName: booking.project_name,
                  title: booking.title,
                  description: booking.description,
                  location: booking.location,
                  rate: booking.rate,
                  totalRate: booking.total_rate,
                  start_date: booking.startDate,
                  end_date: booking.endDate,
                  startTime: booking.booking_start_time,
                  endTime: booking.booking_end_time,
                  currency: booking.currency,
                  duration: booking.duration,
                  is_associated_with_mint: a.is_associated_with_mint,
                  displayName: a.displayName,
                  is_location_added: booking.is_location_added,
                  is_required_all: booking.is_required_all,
                })
              }
            })

          if (a.rating) {
            stars["rating_" + a.id] = a.rating
          }
        })
        that.setState({ ownerData: response.data, all_data: response.data, data: data, originalOwnerData: response.data });
        for (var i = 0; i < response.data.length; i++) {
          keyValue.push(0);
        }
        setTimeout(() => {
          that.updateStars();
        }, 1000)
        // that.setState({all_bookings: all_book, ownerData: response.data, data: all_book})
        that.setSliderPostion();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  changeRating(newRating, name) {
    var freelancer_id = name.replace("rating_", '');
    var resource_type = localStorage.getItem('user_type')
    var resource_id = parseInt(localStorage.getItem('resource_id'))
    var data = {
      resource_type: resource_type,
      resource_id: resource_id,
      newRating: newRating
    }
    var url = `${process.env.REACT_APP_ROUTE}/freelancers/${freelancer_id}/ratings`
    var updatedFreelancers = this.updateRatings(freelancer_id, newRating);
    axios.post(url, data).then((response) => {
      this.setState({ ownerData: updatedFreelancers });
    })
  }
  updateRatings(id, newRating) {
    var updatedFreelancers = [];
    this.state.ownerData.map((item) => {  // eslint-disable-line
      if (item.id === id)
        item.rating = newRating;
      updatedFreelancers.push(item)
    })
    return updatedFreelancers;
  }
  getMonthHeaderText(props) {
    var date = props.date;
    var today = new Date();
    var currentDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
    today = new Date(today.getTime() - (today.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
    var color = today === currentDate ? '#4888FF' : ''
    return (
      <div className="topday" style={{ marginLeft: "26px", fontSize: "12px" }} >
        {props.date.toLocaleString('en-us', { weekday: 'short' }).substr(0, 2)}
        <div className='topdayContent' style={{ background: color, color: today === currentDate ? "white" : "#3F434A", width: "35px", padding: "8px", marginLeft: "-5px", borderRadius: "10px", fontWeight: "400", fontStyle: "normal", fontSize: "22px" }}>
          {parseInt(props.date.toLocaleString('en-us', { day: '2-digit' }), 10)}
        </div>
      </div>);
  }
  resourceHeaderTemplate(props) {
    var date_elm = document.getElementsByClassName("e-tbar-btn-text");
    var todayDate = date_elm[0].innerText.split(' ');
    var currentDate = new Date();
    if (moment(currentDate).format('YYYY') === todayDate[0]) {
      yearData = todayDate[0];
    }
    else if (moment(currentDate).format('YYYY') === todayDate[1]) {
      yearData = todayDate[1];
    }
    else if (moment(currentDate).format('YYYY') === todayDate[3]) {
      yearData = todayDate[3]
    }
    date_elm[0].innerText = ''
    date_elm[0].innerHTML = `<span class='header_year'>${yearData}</span>`

    return (<div className="template-wrap" id={props.resourceData.id}>
      <div className="resource-details" style={{ display: "flex", alignItems: 'center' }}>
        <div className="resource-name">
          <div className="topHederResource">
            <a
              style={{ textDecoration: "none" }}
              href={props.resourceData.portfolio_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="freelacner-name">{props?.resourceData?.displayName !== undefined ? props?.resourceData?.displayName.length > 17 ? props?.resourceData?.displayName.substr(0, 16) + "..." : props?.resourceData?.displayName : ""}</div>
            </a>
            <div
              className="ratings"
              id={"rating_" + props.resourceData.id}
            >
              <div onMouseLeave={() => this.removeStarColor(`rating_${props.resourceData.id}`)}>
                <span
                  onMouseEnter={() => this.setStarColor(`rating_${props.resourceData.id}-${1}`)}
                  onClick={() => this.setStar(`rating_${props.resourceData.id}-${1}`)}
                  className={`rating_${props.resourceData.id}`}
                >
                  {this.state.starsonSearch ?
                    <i className="fa fa-star-o" style={{ color: "rgb(203, 211, 227)", fontSize: "12px", marginLeft: "0px" }}></i> : ""}
                </span>
                <span
                  onMouseEnter={() => this.setStarColor(`rating_${props.resourceData.id}-${2}`)}
                  onClick={() => this.setStar(`rating_${props.resourceData.id}-${2}`)}
                  className={`rating_${props.resourceData.id}`}
                >
                  {this.state.starsonSearch ?
                    <i className="fa fa-star-o" style={{ color: "rgb(203, 211, 227)", fontSize: "12px", marginLeft: "0px" }}></i> : ""}
                </span>
                <span
                  onMouseEnter={() => this.setStarColor(`rating_${props.resourceData.id}-${3}`)}
                  onClick={() => this.setStar(`rating_${props.resourceData.id}-${3}`)}
                  className={`rating_${props.resourceData.id}`}
                >
                  {this.state.starsonSearch ?
                    <i className="fa fa-star-o" style={{ color: "rgb(203, 211, 227)", fontSize: "12px", marginLeft: "0px" }}></i> : ""}
                </span>
              </div>
            </div>
          </div>
          <div className="mint_association">
            {props.resourceData?.profession}
            {props?.resourceData?.is_associated_with_mint && <img className='mintLogo1' src={mintLogo} alt="mintLogo1" />}
          </div>
        </div>
      </div>
    </div>);
  }
  setStar(name) {
    var str = name.split('-');
    var active_index = str[1];
    var classname = str[0]
    stars[classname] = active_index;
    var freelancer_id = classname.replace("rating_", '');
    var resource_type = localStorage.getItem('user_type')
    var resource_id = parseInt(localStorage.getItem('resource_id'))
    var data = {
      resource_type: resource_type,
      resource_id: resource_id,
      newRating: active_index
    }
    var url = `${process.env.REACT_APP_ROUTE}/freelancers/${freelancer_id}/ratings`
    axios.post(url, data).then((response) => { // eslint-disable-line no-unused-vars
    })
    this.enableScrollingOnLeftColumn(1);
  }

  updateStars() {
    Object.keys(stars).forEach(function (key) {
      var all_stars = document.getElementsByClassName(key)
      var value = stars[key];
      Array.from(all_stars).map((star, index) => {  // eslint-disable-line array-callback-return
        if (index + 1 <= value && star.children.length > 0) {
          star.children[0].style.color = 'rgb(254, 181, 11)';
          star.children[0].classList.value = "fa fa-star"
        }
        else if (star.children.length > 0) {
          star.children[0].style.color = 'rgb(203, 211, 227)';
          star.children[0].classList.value = "fa fa-star-o"
        }
      })
    })
  }

  setStarColor(name) {
    var str = name.split('-');
    var active_index = str[1];
    var classname = str[0]
    var all_stars = document.getElementsByClassName(classname)
    Array.from(all_stars).map((star, index) => {  // eslint-disable-line array-callback-return
      if (index + 1 <= active_index && star.children.length > 0) {
        star.children[0].style.color = 'rgb(254, 181, 11)';
        star.children[0].classList.value = "fa fa-star"
      }
      else if (star.children.length > 0) {
        star.children[0].style.color = 'rgb(203, 211, 227)';
        star.children[0].classList.value = "fa fa-star-o"
      }
    })
  }
  removeStarColor(name) {
    var str = name.split('-');
    var classname = str[0]
    var all_stars = document.getElementsByClassName(classname)
    Array.from(all_stars).map((star, index) => {  // eslint-disable-line array-callback-return
      if (index + 1 <= stars[star.classList.value] && star.children.length > 0) {
        star.children[0].style.color = 'rgb(254, 181, 11)';
        star.children[0].classList.value = "fa fa-star"
      }
      else if (star.children.length > 0) {
        star.children[0].style.color = 'rgb(203, 211, 227)';
        star.children[0].classList.value = "fa fa-star-o"

      }
    })
  }
  onActionBegin(args) {
    if (args.requestType === 'toolbarItemRendering') {
      // This block is execute before toolbarItem render
    }
    if (args.requestType === 'dateNavigate') {
      this.enableScrollingOnLeftColumn(1);
      setTimeout(() => {
        this.updateStars();
      }, 500);
      yearFlag = false
      let date_elm = document.getElementsByClassName("e-tbar-btn-text");
      let elem = document.getElementsByClassName('e-day e-title')[0];
      if (date_elm !== undefined && elem !== undefined) {
        date_elm[0].innerHTML = `<span class='header_year'>${elem.innerHTML}</span>`
        yearData = elem.innerHTML
      }
      // This block is executed before previous and next navigation
    }
    if (args.requestType === 'viewNavigate') {
      // This block is execute before view navigation
      // document.location.reload();
    }
    if (args.requestType === 'eventCreate') {
    }
    if (args.requestType === 'eventChange') {
      // This block is execute before an appointment change
      if ((args.data.StartTime) < new Date(new Date().setHours(0, 0, 0, 0))) {
        args.cancel = true;
      }
    }
    if (args.requestType === 'eventRemove') {
      // This block is execute before an appointment remove
    }
  }

  titlelizeString(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  handleCancel = () => {
    this.setState({ isDialogVisible: false });
    this.setSliderPostion();
    setTimeout(() => {
      this.updateStars();
    }, 500);
    this.enableScrollingOnLeftColumn(1);
  };

  async onPopupOpen(args) {
    this.setSliderPostion();
    if (args.data.is_manager) {
      args.cancel = true;
      args.element.classList.add('e-disableCell')
    }
    if (args.type === "QuickInfo") {
      args.cancel = true;
      if (args.data.Id) {
        if ((args.data.status !== "accepted" || args.data.is_manager === true) && args.data.status !== "canceled") {
          this.filterBooking.current = this.state.ownerData[args?.target?.getAttribute("data-group-index")].bookings.filter((item) => {  // eslint-disable-line array-callback-return
            if (moment(args.data.StartTime).format("YYYY-MM-DD") === item.startDate && (args.data.status === "pending" || args.data.status === "accepted")) {
              return item;
            }
          });
          this.selectedDate.current = moment(args.data.StartTime).format("YYYY-MM-DD");
          this.isAssocitedWithMint.current = this.state.ownerData[args?.target?.getAttribute("data-group-index")].is_associated_with_mint;
          this.setState({ isDialogVisible: true });
        }
      }
    } else if (args.type === "Editor") {
      args.cancel = true;
    }
    if (args.data.name === "cellClick") {
      if ((args.data.startTime) < new Date(new Date().setHours(0, 0, 0, 0))) {
      }
    } else {
      if ((args.data.StartTime) < new Date(new Date().setHours(0, 0, 0, 0))) {
      }
    }

    if (args.data.name === "cellClick") {
      if (args.target.className.includes("e-disableCell")) {
        args.cancel = true;
        args.element.classList.add('e-disableCell')
      }
    } else {
      if (args.target && args.target.className.includes("e-disableCell")) {
        args.cancel = true;
        args.element.classList.add('e-disableCell')
      }
    }
    setTimeout(() => {
      this.updateStars();
    }, 500)
  }

  oneventRendered(args) {
    if (args.data.is_manager) {
      args.element.classList.add('e-disableCell')
    } else {
      if (args.data.status === "accepted") {
        args.element.style.backgroundColor = "yellow";
        args.element.style.color = "black";
      }
    }
  }

  onChange(arg) {
    let currentFreelancers = [];
    let query = arg?.target?.value?.toLowerCase();
    if (query === "") {
      this.setState({ starsonSearch: true });
      this.setOriginalData();
      this.fetchData("");
    }
    else {
      currentFreelancers = this.filterData(query);
      if (currentFreelancers.length > 0) this.setState({ ownerData: currentFreelancers });
    }
    setTimeout(() => { this.updateStars() }, 500)
    this.enableScrollingOnLeftColumn(3);
    setTimeout(() => {
      var date_elm = document.getElementsByClassName("e-tbar-btn-text");
      if (date_elm[0]) date_elm[0].innerHTML = `<span class='header_year'>${yearData}</span>`
    }, 1000)
  }

  setOriginalData() {
    this.setState({ ownerData: this.state.originalOwnerData })
  }

  filterData(query) {
    let currentFreelancers = [];
    this.state.originalOwnerData.map((freelancer) => {  // eslint-disable-line array-callback-return
      if ((freelancer.displayName !== "" && freelancer.displayName.toLowerCase().includes(query)) ||
        (freelancer.profession !== "" && (freelancer.profession ? freelancer.profession : '').toLowerCase().replace(/[^a-zA-Z ]/g, " ").includes(query))
        // (freelancer.c_day_rate != "" && freelancer.c_day_rate.toLowerCase().includes(query))
      ) {
        currentFreelancers.push(freelancer)
        this.setState({ starsonSearch: true })
      }
    })
    if (currentFreelancers.length === 0) {
      var dummyData = { bookings: null, color_code: '', displayName: '', portfolio_link: '', profession: '' }
      currentFreelancers.push(dummyData);
      this.setState({ starsonSearch: false })
    }
    return currentFreelancers;
  }

  eventTemplate(props) {
    var tableElement = document.getElementsByClassName("e-schedule-table e-content-table e-auto-height")[0].tBodies[0];
    var filteredStatus = this.state.alldata ? (this.state.alldata.filter(function (item) { return item.Id === props.Id })) : "";
    var status = (filteredStatus[0] && "status" in filteredStatus[0]) ? (filteredStatus[0].status) : props.status;
    var isRequiredAll = (filteredStatus[0] && "is_required_all" in filteredStatus[0]) ? (filteredStatus[0].is_required_all) : props.is_required_all;
    var duration = (filteredStatus[0]&& "duration" in filteredStatus[0]) ? (filteredStatus[0].duration) : props.duration;
    var date = new Date(props.StartTime)
    var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
    var filterOwner = [];
    let count = 0;
    this.updateStars(0);
    this.state.ownerData.map(function (item, i) {  // eslint-disable-line array-callback-return
      if (props.OwnerId === item.id)
        filterOwner = item;
    })
    this.state.ownerData[props.i]?.bookings?.map((item, i) => {  // eslint-disable-line array-callback-return
      let startIndex = moment(props.start_date).format("DD") - 1;
      let endIndex = moment(props.end_date).format("DD") - 1;
      for (let j = startIndex; j <= endIndex; j++) {
        if (item.status === "accepted") {
          tableElement.rows[props.i]?.cells[j].classList.remove('pending');
          tableElement.rows[props.i]?.cells[j].classList.remove('e-disableCell');
          tableElement.rows[props.i]?.cells[j].classList.remove('backendClass');
          tableElement.rows[props.i]?.cells[j].classList.remove('e-busy-weekend');
        }
        else if (item.status === "pending") {
          tableElement.rows[props.i]?.cells[j].classList.remove('e-disableCell');
          tableElement.rows[props.i]?.cells[j].classList.remove('backendClass');
          tableElement.rows[props.i]?.cells[j].classList.remove('e-busy-weekend');
          tableElement.rows[props.i]?.cells[j].classList.remove('accepted');
        }
        tableElement.rows[props.i]?.cells[j].classList.add(`${props.status}`);
      }
    });
    count = this.state.ownerData?.find(x => x.id === props.OwnerId)?.bookings?.reduce((accumulator, currentValue) => {
      if (moment(props?.StartTime)?.format("YYYY-MM-DD") === currentValue.startDate && props?.status === "pending") {
        return accumulator += 1;
      }
      return accumulator;
    }, 0);
    return (
      <>
        {status === "accepted" ? isRequiredAll ?
          (<div style={{ height: "79px", width: `${(83.5 * duration) + (3.5 * duration) - (3.5 * 2)}px`, borderColor: props.colorField, borderRadius: "4px" }}>
            <div style={{ backgroundColor: props.colorField, height: "79px", minWidth: 'fit-content', borderRadius: "4px" }} className="template-wrap" >
              {props.title ?
                <div className="textWrap displayNameOnAcceptedBooking">{props.title}</div>
                : ""}
            </div>
          </div>) : (<div style={{ height: "79px", width: `80px`, borderColor: props.colorField, borderRadius: "4px" }}>
            <div style={{ backgroundColor: props.colorField, height: "79px", width: `80px`, borderRadius: "4px" }} className="template-wrap" >
              {props.title ?
                <div className="textWrap displayNameOnAcceptedBooking" >{props.title?.length > 8 ? props.title?.substr(0, 8) + "..." : props.title}</div>
                : ""}
            </div>
          </div>)
          :
          (status === "canceled" ||
            (filterOwner.length !== 0 && filterOwner?.busy_days?.includes(dateString)) ||
            (filteredStatus.length === 0 && isBusy && filterOwner?.busy_days?.includes(dateString)) ||
            (filteredStatus.length === 0 && !isBusy && !filterOwner?.busy_days?.includes(dateString) && !filterOwner?.bookings?.includes(dateString) && status !== "pending")) ?
            <div className='canceled-booking'></div>
            :
            <div style={{ border: "2px dashed", height: "77px", width: "80px", borderColor: '#3F434ABF', borderRadius: "4px" }}>
              <div style={{ padding: "28px 0px", backgroundColor: '#DEDEDE4D', height: "73px", width: "77px", fontWeight: "700px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "4px" }} className="template-wrap" >
                {props.is_manager ? <div className="textWrap" style={{ color: 'black' }}>{`+${count}`}</div> : ""}
              </div>
            </div>
        }
      </>
    )
  }

  onRenderCell(args) {
    var date_elm = document.getElementsByClassName("e-tbar-btn-text");
    var todayDate = date_elm[0].innerText.split(' ');
    var currentDate = new Date();
    if (moment(currentDate).format('YYYY') === todayDate[0]) {
      yearData = todayDate[0];
    }
    else if (moment(currentDate).format('YYYY') === todayDate[1]) {
      yearData = todayDate[1];
    }
    else if (moment(currentDate).format('YYYY') === todayDate[3]) {
      yearData = todayDate[3]
    }
    date_elm[0].innerText = ''
    date_elm[0].innerHTML = `<span class='header_year'>${yearData}</span>`
    var index = args.groupIndex
    if (!index)
      index = 0
    if (args.date && this.state.ownerData[index] && this.state.ownerData[index].busy_days) {
      var date = new Date(args.date)
      var ownerData = this.state.ownerData[index];
      var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
      var found = this.state.ownerData[index]?.bookings?.find(el => (new Date(el.startDate).getMonth() === args.date.getMonth() && new Date(el.startDate).getYear() === args.date.getYear()));
      if (args.elementType !== 'dateHeader' && !found) {
        args.element.classList.add('empty-row');
      } else {
        var eventCount = this.state.ownerData[index]?.bookings?.filter(function (value) {
          return new Date(value.startDate).getDate() === new Date(args.date).getDate() &&
            value.status !== "canceled" &&
            args.elementType !== 'dateHeader' &&
            new Date(value.startDate).getMonth() === args.date.getMonth() &&
            new Date(value.startDate).getYear() === args.date.getYear();
        }).length
        if (keyValue[index] < eventCount)
          keyValue[index] = eventCount
      }
      if (this.state.ownerData[index].busy_days.includes(dateString)) {
        var bookings = [];
        if (ownerData.bookings) {
          bookings = ownerData.bookings.filter(booking => (booking.profile_id === ownerData.id && booking.status === "accepted" && booking.startDate === dateString))
        }
        if (bookings.length === 0) {
          args.element.classList.add('e-disableCell');
          args.element.classList.add('backendClass');
          if (!Array.from(args.element.classList).includes("e-work-days") && !Array.from(args.element.classList).includes("e-header-cells")) {
            args.element.classList.add('e-busy-weekend');
          }
        }
        else {
          if (bookings[0].startDate !== dateString) {
            if (!Array.from(args.element.classList).includes("e-work-days") && !Array.from(args.element.classList).includes("e-header-cells")) {
              args.element.classList.add('e-disableCell');
              args.element.classList.add('backendClass');
              args.element.classList.add('e-busy-weekend');
            }
          }
        }
      }
    }
    if (Array.from(args.element.classList).includes("e-work-cells", "e-child-node")) {
      args.element.classList.add('agency_view');
    }
  }

  logoutClick() {
    localStorage.removeItem("color_code")
    localStorage.removeItem("user_type")
    localStorage.removeItem("resource_id")
    localStorage.removeItem("phone")
    localStorage.removeItem("agency")
    logout()
    window.location = "/login"
  }

  onCreated() {
    this.scheduleObj.selectedDate = new Date();
    this.scheduleObj.scrollTo(null, this.scheduleObj.selectedDate);
  }

  memoizedScheduleComponent = memoizeOne((data, ownerData) => (
    <ScheduleComponent
      style={{ position: 'fixed' }}
      width='100%'
      height={window.innerHeight + 50}
      id="ScheduleComponent" rowAutoHeight={true} renderCell={this.onRenderCell.bind(this)} ref={t => this.scheduleObj = t} popupOpen={this.onPopupOpen.bind(this)}
      group={{ resources: ['Owners'] }} eventSettings={{ dataSource: data, template: this.eventTemplate.bind(this) }}
      resourceHeaderTemplate={this.resourceHeaderTemplate.bind(this)}
      dateHeaderTemplate={this.getMonthHeaderText.bind(this)}
      actionBegin={this.onActionBegin.bind(this)} eventRendered={this.oneventRendered.bind(this)} workDays={[1, 2, 3, 4, 5]}
      created={this.onCreated.bind(this)}
      enablePersistence={true}
      delayUpdate='false'
    >
      <ResourcesDirective>
        <ResourceDirective
          field='OwnerId'
          title='Owner'
          name='Owners'
          dataSource={ownerData}
          textField='displayName'
          idField='id'
          colorField='color_code1'
        >
        </ResourceDirective>
      </ResourcesDirective >

      <HeaderRowsDirective>
        <HeaderRowDirective option='Month' />
        <HeaderRowDirective option='Date' />
        <HeaderRowDirective option='Hour' />
      </HeaderRowsDirective>

      <ViewsDirective>
        <ViewDirective option="TimelineMonth" displayName="Vertical TimelineMonth" isSelected={true} orientation="Vertical" />
      </ViewsDirective>
      <form className="example" action="">
        <svg className="customBtn" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M1.75 7C1.75 4.1005 4.1005 1.75 7 1.75C9.8995 1.75 12.25 4.1005 12.25 7C12.25 9.8995 9.8995 12.25 7 12.25C4.1005 12.25 1.75 9.8995 1.75 7ZM7 0.25C3.27208 0.25 0.25 3.27208 0.25 7C0.25 10.7279 3.27208 13.75 7 13.75C8.59376 13.75 10.0585 13.1976 11.2133 12.2739L14.7192 15.7799C15.0121 16.0728 15.487 16.0728 15.7799 15.7799C16.0728 15.487 16.0728 15.0121 15.7799 14.7192L12.2739 11.2133C13.1976 10.0585 13.75 8.59376 13.75 7C13.75 3.27208 10.7279 0.25 7 0.25Z" fill="#828282" />
        </svg>
        <input className="searchInput" type="text" placeholder="" name="search" onChange={this.debouncedOnChange} />
        <svg className="customFilterBtn" width="16" height="16" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M0.75 9.51526C0.335786 9.51526 0 9.85689 0 10.2783C0 10.6997 0.335786 11.0414 0.75 11.0414H6.38888C6.8031 11.0414 7.13888 10.6997 7.13888 10.2783C7.13888 9.85689 6.8031 9.51526 6.38888 9.51526H0.75ZM15.2492 11.0414H10.3607V12.737C10.3607 13.1584 10.0249 13.5 9.61072 13.5C9.1965 13.5 8.86072 13.1584 8.86072 12.737V10.3024C8.86047 10.2944 8.86035 10.2864 8.86035 10.2783C8.86035 10.2703 8.86047 10.2622 8.86072 10.2542V7.81956C8.86072 7.39814 9.1965 7.05651 9.61072 7.05651C10.0249 7.05651 10.3607 7.39814 10.3607 7.81956V9.51526H15.2492C15.6634 9.51526 15.9992 9.85689 15.9992 10.2783C15.9992 10.6997 15.6634 11.0414 15.2492 11.0414ZM7.25 3.72163C7.25 3.30022 7.58579 2.95859 8 2.95859L15.25 2.95859C15.6642 2.95859 16 3.30022 16 3.72163C16 4.14305 15.6642 4.48468 15.25 4.48468L8 4.48468C7.58579 4.48468 7.25 4.14305 7.25 3.72163ZM0.75 2.95859C0.335786 2.95859 0 3.30022 0 3.72163C0 4.14305 0.335786 4.48468 0.75 4.48468H4.02734V6.18041C4.02734 6.60182 4.36313 6.94345 4.77734 6.94345C5.19156 6.94345 5.52734 6.60182 5.52734 6.18041V3.74774C5.52763 3.73907 5.52777 3.73037 5.52777 3.72163C5.52777 3.7129 5.52763 3.7042 5.52734 3.69553V1.26301C5.52734 0.841589 5.19156 0.499963 4.77734 0.499963C4.36313 0.499963 4.02734 0.841589 4.02734 1.26301V2.95859H0.75Z" fill="#8A9099" />
        </svg>
      </form>
      <Inject services={[Week, WorkWeek, Month, Agenda, MonthAgenda, TimelineViews, TimelineMonth]} />
    </ScheduleComponent>
  ))

  render() {
    const scheduleComponent = this.memoizedScheduleComponent(this.state.data, this.state.ownerData)
    this.setSliderPostion();

    return <div className="agency" id="agency">
      <BookingDetailsDialog isDialogVisible={this.state.isDialogVisible} handleCancel={this.handleCancel} filterBooking={this.filterBooking.current} selectedDate={this.selectedDate.current} isAssocitedWithMint={this.isAssocitedWithMint.current} />

      {scheduleComponent}

      {this.state.ownerData.length === 0 && <CustomSkeleton />}

      <div id="logout-button">
        <button id="btn" onClick={this.logoutClick}>LOG OUT</button>
      </div>
    </div >
  }
}