import * as React from 'react';
import { Menu, Box, MenuItem, Link } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InvitationDialog from '../Dashboard/InvitationDialog';
import CancelDialoag from './CancelDialoag';

const EditMenuDialog = (props) => {
  const [open, setOpen] = React.useState(false);
  const [copen, setCOpen] = React.useState(false);
  const openInvitationDialog = () => {
    props.setOpen();
    setOpen(!open);
  };
  const openCancelDialoag = () => {
    props.setOpen();
    setCOpen(!copen);
  }
  const parentUpdate = () => {
    props.setOpen();
  }
  return (
    <>
      <Menu
        sx={{ mt: props.open && props.topPos != null && parseInt(props?.topPos) > 540 ? `${parseInt(props?.topPos) - 100}px` : `${props.topPos}px`, ml: '110px', height: '116px', width: '145px' }}
        id="menu-appbar1"
        anchorEl={props.open}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        open={Boolean(props.open)}
        onClose={props.setOpen}
        style={{ height: "fitContent" }}
        className="freelancerActionMenu"
      >
        <Box>
          {props.phone &&
            <MenuItem style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <WhatsAppIcon sx={{ fontSize: '15px', color: '#66CF5D !important', marginLeft: '2px', marginRight: '3px', fontWeight: '400' }} />
              <Link className='menuLink' sx={{ fontSize: '14px !important', color: '#595F69 !important', marginLeft: '5px', fontWeight: '400' }}
                href={`https://api.whatsapp.com/send/?phone=%2B${props.phone}&text=${"hello"}&type=phone_number&app_absent=0`} target='_blank'  >WhatsApp</Link>
            </MenuItem>
          }

          <MenuItem onClick={() => { openInvitationDialog(); }} style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <EditOutlinedIcon style={{ fontSize: '15px', color: '#595F69', marginLeft: '1px', marginRight: '5px', fontWeight: '400' }} />
            <Link className='menuLink' sx={{ fontSize: '15px', color: '#595F69 !important', marginLeft: '5px', fontWeight: '400' }} >Edit</Link>
          </MenuItem>

          <MenuItem onClick={() => { openCancelDialoag(); }} style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <DeleteForeverOutlinedIcon style={{ fontSize: '15px', color: '#FD7872', marginRight: '5px', fontWeight: '400' }} />
            <Link className='menuLink' sx={{ fontSize: '15px', color: '#FD7872 !important', marginLeft: '5px', fontWeight: '400' }}  >Archive</Link>
          </MenuItem>
        </Box>
      </Menu>

      {open && <InvitationDialog open={open} setOpen={setOpen} userId={props.userId} displayName={props.displayName} profession={props.profession} email={props.email} portfolio_link={props.portfolio_link} fetchData={props.fetchData} parentUpdateHandler={parentUpdate} />}
      {copen && <CancelDialoag open={copen} setCOpen={setCOpen} userId={props.userId} displayName={props.displayName} parentUpdateHandler={parentUpdate} fetchData={props.fetchData} />}
    </>
  )
};
export default EditMenuDialog;