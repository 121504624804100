import React from 'react'
import { usePWAInstall } from 'react-use-pwa-install';
import LoadingButton from '@mui/lab/LoadingButton';
const PwaButton = () => {
    const install = usePWAInstall();
    let element = document.getElementById('pwa-btn')
    // For iOS devices
    if (window.navigator.standalone) {
        // PWA app is already installed on the device
        if (element) {
            element.style.display = 'none'
        }
        else if (element) {
            element.style.display = 'block'
        }
    }
    // For Android devices
    if (window.matchMedia('(display-mode: standalone)').matches) {
        // PWA app is already installed on the device
        if (element) {
            element.style.display = 'none'
        }
        else if (element) {
            element.style.display = 'block'
        }
    }
    return (
        <>
            {install &&
                <LoadingButton
                    variant="contained"
                    id='pwa-btn'
                    className='pwa-btn'
                    onClick={install}
                >
                    Download App
                </LoadingButton>
            }
        </>
    )
}

export default PwaButton