import * as React from 'react';
import { Box, Link, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Avatar, ListItem, ListItemText, Grid, Alert, Typography } from '@mui/material';
import { FixedSizeList } from 'react-window';
import LoadingButton from '@mui/lab/LoadingButton';
import useForm from "../Register/useForm";
import { validator, validateAllError } from "../Register/validator";
import Send from "../../assets/Send.png";
import Check from "../../assets/Check.png";
import CloseIcon from '@mui/icons-material/Close';
import { GetInvitesApi, sendInviteApi, UpdateInviteApi } from '../Api';

export default function InvitationDialog(props) {
  const initState = {
    email: props.userId && props.userId != null && props.email ? props.email : '',
    profession: props.userId && props.userId != null && props.profession ? props.profession : '',
    link: props.userId && props.userId != null && props.portfolio_link ? props.portfolio_link : '',
  };
  const [values, setValues] = React.useState({
    email: '',
    error: false,
    msg: '',
    emailMsg: '',
    showAlert: false,
    loading: false,
    invitations: [],
    isUpdated: false,
    total: 0,
  });

  React.useEffect(() => {
    if (window.location.origin.includes('https://www.flxibl.io')) {
      window.location.replace("https://www.flxibl.com" + window.location.pathname);
    }
    if (!values.error)
      GetInvitesApi(values, setValues);
  }, [values.msg]);  // eslint-disable-line react-hooks/exhaustive-deps

  const { handleChange, handleBlur, state, errors, setErrors } = useForm({
    initState,
    validator,
  });

  const inviteChangeHandler = () => {
    setValues({ ...values, isUpdated: true })
  }
  const handleInvitationSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const body = {
      email: data.get('email'),
      profession: data.get('profession'),
      portfolio_link: data.get('link'),
    }
    if (data.get('email') && data.get('profession') && errors.email === undefined && errors.profession === undefined && data.get('email') !== localStorage.getItem('email')) {
      setValues((values) => {
        return ({
          ...values,
          loading: true,
          showAlert: false,
        })
      });
      await sendInviteApi(data, values, setValues, document.getElementsByClassName("invitationForm")[0]);
      state.profession = '';
      state.email = '';
      state.link = '';
      sessionStorage.setItem("invitionSent","true");
    } else if (data.get('email') === localStorage.getItem('email')) {
      setValues((values) => {
        return ({
          ...values,
          showAlert: true,
          msg: `You can't invite yourself`,
        })
      });
    } else {
      setValues((values) => {
        return ({
          ...values,
          showAlert: false,
        })
      });
      validateAllError(['email', 'profession'], body, setErrors);
    }
  }

  const handleUpdateInvitationSubmit = async (event) => {
    event.preventDefault();
    const fId = props.userId;
    const body = {
      email: state.email,
      profession: state.profession,
      portfolio_link: state.link,
    }
    if (state.email && state.profession && errors.email === undefined && errors.profession === undefined && state.email !== localStorage.getItem('email')) {
      setValues((values) => {
        return ({
          ...values,
          loading: true,
          showAlert: false,
        })
      });

      await UpdateInviteApi(body, values, setValues, fId)
      state.profession = '';
      state.email = '';
      state.link = '';
      props.parentUpdateHandler();
      props.fetchData(" ");
      props.setOpen()
    } else {
      setValues((values) => {
        return ({
          ...values,
          showAlert: false,
        })
      });
      validateAllError(['email', 'profession'], body, setErrors);
    }
  }

  function smallScreenDataPopulation(input) {
    if (input?.length > 3 && !(window.innerWidth > 900)) {
      return `${input.substring(0, 3)}...`
    }
    else {
      return input
    }
  }

  function renderRow(props) {
    const { index, style } = props;

    localStorage.setItem("invitation_count", values.total);
    return (
      <Box sx={{ height: '10px', position: 'relative' }}>
        <Grid container style={style} className="invitedPersonRow">
          <Grid item xs={4}>
            <ListItem key={index} component="div" disablePadding>
              <ListItemText className="listItem" primary={window.innerWidth > 900 ? values.invitations[index].email : smallScreenDataPopulation(values.invitations[index].email)} />
            </ListItem>
          </Grid>

          <Grid item xs={4}>
            <ListItem key={index} component="div" disablePadding>
              <ListItemText className="listItem" primary={window.innerWidth > 900 ? values.invitations[index].profession : smallScreenDataPopulation(values.invitations[index].profession)} />
            </ListItem>
          </Grid>

          <Grid item xs={3}>
            <ListItem key={index} component="div" disablePadding>
              <ListItemText className="listItem">
                <Link className='listItemLink' sx={{ color: '#8A9099', textDecoration: 'underline', }} href={values.invitations[index].portfolio_link} >{window.innerWidth > 900 ? values.invitations[index].portfolio_link : smallScreenDataPopulation(values.invitations[index].portfolio_link)}</Link>
              </ListItemText>
            </ListItem>
          </Grid>

          <Grid item xs={1} className="tickSign">
            <Avatar className="checkAvatar" src={Check} />
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={props.open}
        scroll="body"
        onClose={() => { props.setOpen(); setErrors({}); setValues({ ...values, showAlert: false }) }}
        className="inivitationDialog"
      >
        <DialogActions className='closeBar'>
          <CloseIcon onClick={() => props.setOpen()} className='closeButton' color='action' />
        </DialogActions>
        {values?.showAlert && <Alert onClose={() => { setValues({ ...values, showAlert: false }) }} className="alert inviteAlert" variant="filled" severity={values?.error ? "error" : "success"}>
          {values?.msg}
        </Alert>}
        <DialogTitle className='invitePopupTitle'>{props.userId && props.userId != null && props.displayName ? `Edit ${props.displayName}` : "Invite Your Freelancers"}</DialogTitle>

        <DialogContent>
          <Box
            noValidate
            component="form"
            className='invitationForm'
            onSubmit={handleInvitationSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'auto',
            }}
          >
            <TextField
              label="Email"
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              className={"email inviteField"}
              value={state.email}
              helperText={errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{ disableUnderline: true, autoComplete: "off", readOnly: props.userId && props.userId != null ? true : false }}
              InputLabelProps={{ shrink: true, style: { fontSize: 19, marginTop: -4, color: "#8A9099" } }}
            />

            <TextField
              label="Role"
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="profession"
              name="profession"
              value={state.profession}
              autoComplete="profession"
              className={"email inviteField"}
              helperText={errors.profession}
              onChange={(e) => {
                props.userId && props.userId != null && inviteChangeHandler()
                handleChange(e)
              }}
              onBlur={handleBlur}
              InputProps={{ disableUnderline: true, autoComplete: "off" }}
              InputLabelProps={{ shrink: true, style: { fontSize: 19, marginTop: -4, color: "#8A9099" } }}
            />

            <TextField
              label="Profile Link (For Reference)"
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="link"
              name="link"
              autoComplete="bio"
              className="email inviteField"
              value={state.link}
              onChange={(e) => {
                props.userId && props.userId != null && inviteChangeHandler()
                handleChange(e)
              }}
              onBlur={handleBlur}
              InputProps={{ disableUnderline: true, autoComplete: "off" }}
              InputLabelProps={{ shrink: true, style: { fontSize: 19, marginTop: -4, color: "#8A9099" } }}
              sx={{ textDecoration: 'underline' }}
            />
            {!props.userId && props.userId == null &&
              <>
                <LoadingButton
                  type="submit"
                  loading={values.loading}
                  className="sendBtn"
                  loadingPosition="center"
                  variant="contained"
                >
                  {!values.loading && <Avatar className="sendAvatar" src={Send} onClick={() => { localStorage.removeItem("invitation_count") }} />}
                </LoadingButton>
              </>}
          </Box>

          {!props.userId && props.userId == null &&
            <>
              <DialogContentText className="invitationFormText">Invited({values.total})</DialogContentText>
              <FixedSizeList
                height={160}
                width='auto'
                itemSize={46}
                itemCount={values.total}
                overscanCount={5}
              >
                {renderRow}
              </FixedSizeList>
            </>}
          {props.userId && props.userId != null &&
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              className='bookBtn'
              sx={{ mt: 3, mb: 2 }}
              disabled={values.isUpdated ? false : true}
              onClick={handleUpdateInvitationSubmit}

            >
              <Typography className="bookBtnText">Update</Typography>
            </LoadingButton>

          }

        </DialogContent>
      </Dialog>
    </>
  );
}