import React from 'react';
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, Alert, Typography, DialogContentText } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { getToken } from "../utils"

export default function CancelDialoag(props) {

  const [values, setValues] = React.useState({
    email: '',
    error: false,
    msg: '',
    emailMsg: '',
    showAlert: false,
    loading: false,

  });
  const removeFreelancer = async () => {
    setValues({ ...values, loading: true });
    await axios.delete(`${process.env.REACT_APP_ROUTE}/remove_connection/` + props.userId, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + getToken()
      },
      mode: 'cors',
      credentials: 'include'
    }).then((res) => {
      setValues({ ...values, loading: false });
      sessionStorage.setItem("freelancerDeleted","true");
      props.parentUpdateHandler();
      props.fetchData(" ");
      setTimeout(() => {
        props.setCOpen()
      }, 100)

    })
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={props.open}
        scroll="body"
        onClose={() => { props.setCOpen(); setValues({ ...values, showAlert: false }) }}
        className="inivitationDialog"
      >
        <DialogActions className='closeBar'>
          <CloseIcon onClick={() => props.setCOpen()} className='closeButton' color='action' />
        </DialogActions>
        {values?.showAlert && <Alert onClose={() => { setValues({ ...values, showAlert: false }) }} className="alert inviteAlert" variant="filled" severity={values?.error ? "error" : "success"}>
          {values?.msg}
        </Alert>}
        <DialogTitle className='invitePopupTitle'>{props.displayName}</DialogTitle>

        <DialogContent>
          <DialogContentText sx={{ textAlign: 'center' }}>
            <Typography className="transparentBtnText"> Are you sure you want to remove {props.displayName} from your network?</Typography>
          </DialogContentText>

          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>

              <LoadingButton
                type="submit"
                variant="contained"
                className='transparentbtn'
                sx={{ mt: 3, mb: 2 }}
                onClick={() => props.setCOpen()}
              >
                <Typography className="transparentBtnText">Cancel</Typography>
              </LoadingButton>

            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <LoadingButton
                type="submit"
                variant="contained"
                className='archiveCancelBtn'
                sx={{ mt: 3, mb: 2 }}
                onClick={removeFreelancer}
                loading={values.loading}

              >
                <Typography className="bookBtnText">Yes</Typography>
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}